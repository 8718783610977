import React from 'react';
import PropTypes from 'prop-types';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import { DraftTrackerLeaderboardTable } from './table/DraftTrackerLeaderboardTable';
import { ClubNavItem, RoundNavItem, Search } from './nav-items';
import { Legend } from './table/components/Legend';

export const DraftTrackerLeaderboard = ({ seasonPid, seasonId }) => {
    document.body.classList.add('remove-sticky-nav');
    const year = seasonPid.substring(4, 8);

    return (
        <>
            <StatsSection.Main>
                <StatsSection.Nav>
                    <StatsSection.NavItems>
                        <RoundNavItem year={year} />
                        <ClubNavItem seasonId={seasonId} year={year} />
                    </StatsSection.NavItems>
                    <StatsSection.AdditionalNavItems>
                        <Search />
                    </StatsSection.AdditionalNavItems>
                </StatsSection.Nav>
                <StatsSection.Content>
                    <StatsSection.ContentBody>
                        <DraftTrackerLeaderboardTable year={year} />
                    </StatsSection.ContentBody>
                </StatsSection.Content>
            </StatsSection.Main>
            <Legend />
        </>
    );
};

DraftTrackerLeaderboard.propTypes = {
    seasonPid: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired
};
