/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import classNames from 'classnames';
// eslint-disable-next-line id-length
import TableModalSortableFieldItem from './TableModalSortableFieldItem';
import TableModalStatGroup from './TableModalStatGroup';

const TableModal = ({
    state,
    customTable,
    tableName,
    toggleModal,
    modalOpen,
    handleAddRemoveFieldClick,
    handleRemoveAllFieldsClick,
    moveCard,
    getTranslation,
    getFallbackTranslation,
    confirmFields,
    setAccordionItem,
    getAccordionItem,
    setDescriptionShowing,
    getDescriptionShowing,
    bodyClick,
    closeDescription
}) => {
    useEffect(() => {
        const tab = document
            .querySelector('[data-tablename="' + tableName + '"]')
            ?.closest('.js-mc-tab');

        if (tab) {
            if (modalOpen && !tab.classList.contains('is-modal-open')) {
                tab.classList.add('is-modal-open');
            } else if (!modalOpen && tab.classList.contains('is-modal-open')) {
                tab.classList.remove('is-modal-open');
            }
        }
    }, [modalOpen]);

    const modalClass = classNames('modal-wrap', {
        'modal-wrap modal-wrap--custom-table open': modalOpen,
        'modal-wrap modal-wrap--custom-table close': !modalOpen
    });

    const tickIcon = PULSE.app.templating.render(
        {
            cssClass: 'custom-table-modal__confirm-stats-button-icon',
            name: 'tick'
        },
        'common.svg-icon'
    );

    const modalCloseIcon = PULSE.app.templating.render(
        {
            cssClass: 'custom-table-modal__close-icon',
            name: 'close'
        },
        'common.svg-icon'
    );

    const chevronIcon = PULSE.app.templating.render(
        {
            cssClass: 'custom-table-modal__toggle-sidebar-button-icon',
            name: 'chevron'
        },
        'common.svg-icon'
    );

    const myIcon = PULSE.app.templating.render(
        {
            cssClass: 'custom-table-modal__top-bar-subtitle-icon',
            name: 'my-icon-no-fill'
        },
        'common.svg-icon'
    );

    const infoIcon = PULSE.app.templating.render(
        {
            cssClass: '',
            name: 'info'
        },
        'common.svg-icon'
    );

    let tableModalFieldList = (
        <li className="custom-table-modal__field-list-item custom-table-modal__field-list-item--no-data">
            <h4 className="custom-table-modal__field-list-item-title">
                {getFallbackTranslation(
                    `label.customTable.${tableName}.modal.sidebar.noData.title`,
                    'Add your first field'
                )}
            </h4>
            <span className="custom-table-modal__field-list-item-subtitle">
                {getFallbackTranslation(
                    `label.customTable.${tableName}.modal.sidebar.noData.subtitle`,
                    'Select fields on the right…'
                )}
            </span>
        </li>
    );

    // Check for visibility condition functions and fire any found
    const filtereredFields = state.displayedFields.filter((field) => {
        if (typeof field.visibiltyConditionFunc !== 'function') {
            return true;
        }

        return field.visibiltyConditionFunc(customTable.dataJson);
    });

    const isPersistentFields = filtereredFields.filter((field) => {
        return field.isPersistent;
    });

    // Check there is fields and the fields are longer than the persistent fields
    if (
        filtereredFields.length > 0 &&
        filtereredFields.length > isPersistentFields.length
    ) {
        tableModalFieldList = filtereredFields.map((field, i) => {
            return (
                <TableModalSortableFieldItem
                    key={field.fieldName + i}
                    tableName={tableName}
                    field={field}
                    fieldId={i}
                    index={i}
                    handleAddRemoveFieldClick={handleAddRemoveFieldClick}
                    moveCard={moveCard}
                    getTranslation={getTranslation}
                />
            );
        });
    }

    return (
        <div
            id="${anchorName}"
            className={modalClass}
            onClick={(evt) => bodyClick(evt)}
        >
            <div className="custom-table-modal">
                <div className="custom-table-modal__inner-wrapper js-floating-bar-scroll">
                    <div className="custom-table-modal__top-bar js-bottom-bar">
                        <div className="custom-table-modal__top-bar-title-container">
                            <h3 className="custom-table-modal__top-bar-subtitle">
                                <span
                                    dangerouslySetInnerHTML={{ __html: myIcon }}
                                ></span>
                                {getFallbackTranslation(
                                    `label.customTable.${tableName}.modal.subtitle`,
                                    'Custom Table'
                                )}
                            </h3>
                            <h2 className="custom-table-modal__top-bar-title">
                                {getFallbackTranslation(
                                    `label.customTable.${tableName}.modal.title`,
                                    'Edit Your Table'
                                )}
                            </h2>
                        </div>
                        <div className="custom-table-modal__top-bar-button-container">
                            {confirmFields && (
                                <button
                                    className="custom-table-modal__confirm-stats-button u-hide-until-tablet"
                                    onClick={() => confirmFields(tableName)}
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: tickIcon
                                        }}
                                    ></span>
                                    {getFallbackTranslation(
                                        `label.customTable.${tableName}.modal.confirmButton`,
                                        'Confirm'
                                    )}
                                </button>
                            )}
                            <button
                                className="custom-table-modal__close"
                                onClick={() => toggleModal(tableName)}
                            >
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: modalCloseIcon
                                    }}
                                ></span>
                            </button>
                        </div>
                    </div>
                    <div className="custom-table-modal__content">
                        <div className="custom-table-modal__sidebar">
                            <div
                                className={`custom-table-modal__title-subtitle-container ${
                                    getAccordionItem('sidebar') ? 'is-open' : ''
                                }`}
                            >
                                <div className="custom-table-modal__title-container">
                                    <h4 className="custom-table-modal__title">
                                        <span className="custom-table-modal__fields-counter u-hide-from-tablet">
                                            {filtereredFields.length -
                                                isPersistentFields.length}
                                        </span>
                                        {getFallbackTranslation(
                                            `label.customTable.${tableName}.modal.sidebar.title`,
                                            'Your Fields'
                                        )}
                                        <span
                                            className={`custom-table-modal__title-open-text u-hide-from-tablet ${
                                                getAccordionItem('sidebar')
                                                    ? 'u-hide'
                                                    : ''
                                            }`}
                                        >
                                            {getFallbackTranslation(
                                                `label.customTable.${tableName}.modal.sidebar.openTitle`,
                                                'label.customTable.modal.sidebar.openTitle'
                                            )}
                                        </span>
                                    </h4>
                                    <button
                                        className="custom-table-modal__sidebar-button  u-hide-until-tablet"
                                        onClick={() =>
                                            handleRemoveAllFieldsClick(
                                                tableName
                                            )
                                        }
                                    >
                                        <span className="custom-table-modal__add-remove-button-icon custom-table-modal__add-remove-button-icon--remove"></span>
                                        {getFallbackTranslation(
                                            `label.customTable.${tableName}.modal.sidebar.removeAllButton`,
                                            'Remove All Fields'
                                        )}
                                    </button>
                                    <button
                                        className={`custom-table-modal__toggle-sidebar-button u-hide-from-tablet ${
                                            getAccordionItem('sidebar')
                                                ? 'is-open'
                                                : 'is-closed'
                                        }`}
                                        onClick={() =>
                                            setAccordionItem('sidebar')
                                        }
                                    >
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: chevronIcon
                                            }}
                                        ></span>
                                    </button>
                                </div>
                                <div
                                    className={`custom-table-modal__subtitle-container ${
                                        getAccordionItem('sidebar')
                                            ? ''
                                            : 'u-hide'
                                    }`}
                                >
                                    <span
                                        className="custom-table-modal__sidebar-info-icon u-hide-from-tablet"
                                        dangerouslySetInnerHTML={{
                                            __html: infoIcon
                                        }}
                                    ></span>
                                    <span className="custom-table-modal__subtitle">
                                        {getFallbackTranslation(
                                            `label.customTable.${tableName}.modal.sidebar.subtitle`,
                                            'Remove and Re-order your chosen fields'
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div
                                className={`custom-table-modal__field-list-container ${
                                    getAccordionItem('sidebar')
                                        ? 'is-open'
                                        : 'is-closed'
                                }`}
                            >
                                <ul className="custom-table-modal__field-list">
                                    {tableModalFieldList}
                                    <li
                                        key="custom-table-modal__remove-fields"
                                        className="custom-table-modal__field-list-item custom-table-modal__field-list-item--remove u-hide-from-tablet"
                                    >
                                        <button
                                            className="custom-table-modal__sidebar-button"
                                            onClick={() =>
                                                handleRemoveAllFieldsClick(
                                                    tableName
                                                )
                                            }
                                        >
                                            <span className="custom-table-modal__add-remove-button-icon custom-table-modal__add-remove-button-icon--remove"></span>
                                            {getFallbackTranslation(
                                                `label.customTable.${tableName}.modal.sidebar.removeAllButton`,
                                                'Remove All Fields'
                                            )}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="custom-table-modal__main-content">
                            <div className="custom-table-modal__title-subtitle-container">
                                <div className="custom-table-modal__title-container">
                                    <div className="custom-table-modal__title-wrapper">
                                        <h4 className="custom-table-modal__title">
                                            {getFallbackTranslation(
                                                `label.customTable.${tableName}.modal.chooseFields.title`,
                                                'Available Fields'
                                            )}
                                        </h4>
                                        <span className="custom-table-modal__subtitle u-hide-from-tablet">
                                            {getFallbackTranslation(
                                                `label.customTable.${tableName}.modal.chooseFields.subtitle.left`,
                                                'Choose which fields youd like to show…'
                                            )}
                                        </span>
                                    </div>
                                    <div className="custom-table-modal__title-subtitle-icon-container">
                                        <span
                                            className="u-hide-until-tablet"
                                            dangerouslySetInnerHTML={{
                                                __html: infoIcon
                                            }}
                                        ></span>
                                        <span
                                            className="custom-table-modal__title-subtitle"
                                            dangerouslySetInnerHTML={{
                                                __html: getFallbackTranslation(
                                                    `label.customTable.${tableName}.modal.chooseFields.secondaryTitle`,
                                                    'Your custom fields|will only be saved on this device',
                                                    'custom-table-modal__title-subtitle--bold'
                                                )
                                            }}
                                        ></span>
                                    </div>
                                </div>
                                <div className="custom-table-modal__subtitle-container u-hide-until-tablet">
                                    <span className="custom-table-modal__subtitle">
                                        {getFallbackTranslation(
                                            `label.customTable.${tableName}.modal.chooseFields.subtitle.left`,
                                            'Choose which fields youd like to show…'
                                        )}
                                    </span>
                                    <span className="custom-table-modal__subtitle">
                                        {getFallbackTranslation(
                                            `label.customTable.${tableName}.modal.chooseFields.subtitle.left`,
                                            'Hover field for more information'
                                        )}
                                    </span>
                                </div>
                            </div>

                            <div className="custom-table-modal__stats-container">
                                {Object.keys(state.tableFieldGroups).map(
                                    (key) => (
                                        <TableModalStatGroup
                                            key={key}
                                            state={state}
                                            fieldKey={key}
                                            tableName={tableName}
                                            handleAddRemoveFieldClick={
                                                handleAddRemoveFieldClick
                                            }
                                            getTranslation={getTranslation}
                                            setAccordionItem={setAccordionItem}
                                            getAccordionItem={getAccordionItem}
                                            setDescriptionShowing={
                                                setDescriptionShowing
                                            }
                                            getDescriptionShowing={
                                                getDescriptionShowing
                                            }
                                            closeDescription={closeDescription}
                                            customTable={customTable}
                                        />
                                    )
                                )}
                            </div>
                        </div>

                        {confirmFields && (
                            <div className="custom-table-modal__mobile-button-container u-hide-from-tablet">
                                <button
                                    className="button custom-table-modal__confirm-stats-button-mobile"
                                    onClick={() => confirmFields(tableName)}
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: tickIcon
                                        }}
                                    ></span>
                                    {getFallbackTranslation(
                                        `label.customTable.${tableName}.modal.confirmButton`,
                                        'Confirm'
                                    )}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableModal;
