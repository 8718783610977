/**
 * @param {object} item - Item..
 * @param {object[]} columns - Column data.
 * @param {Array} rounds - rounds list.
 * @returns {object[]} Row data for the given player.
 */
export const composeRowData = (item, columns, rounds) => {
    const byeRound =
        rounds?.find((round) =>
            round.byes?.find((bye) => bye.providerId === item?.team.teamId)
        )?.roundNumber ?? null;

    return {
        player: {
            eligible: item?.eligible ?? false,
            givenName: item?.player.givenName ?? '',
            surname: item?.player.surname ?? '',
            photoURL: item?.player.photoURL ?? '',
            playerId: item?.player.playerId ?? '',
            winner: item?.player.winner ?? false
        },
        ...columns.reduce((prev, curr) => {
            let votes = '';
            if (byeRound && curr.accessor === 'round' + byeRound) {
                votes = 'B';
            } else {
                item?.roundByRoundVotes?.forEach((round) => {
                    if (curr.accessor === 'round' + round.roundNumber) {
                        votes = round.votes;
                        return;
                    }
                });
            }

            return {
                ...prev,
                [curr.accessor]: votes
            };
        }, {}),
        totalVotes: item?.totalVotes ?? 0,
        bettingOdds: item?.bettingOdds ?? 0
    };
};
