import { Fragment, createElement } from 'react';
import { createRoot } from 'react-dom/client';
import { createPortal } from 'react-dom';

import {
    DefaultTableProvider,
    DefaultTableModalProvider,
    LadderTableProvider,
    RankingsTableProvider,
    PlayerStatsTableProvider
} from '../../custom-table/js/providers';
import {
    SeasonLeadersProvider,
    StatsLeadersProvider,
    GlossaryProvider,
    StatsProHeaderProvider,
    LastRoundHighsProvider,
    CareerAndSeasonStatsProvider,
    PlayerProfileBioProvider,
    KeyStatsAndPerformanceProvider,
    PerformanceChartProvider,
    PlayerComparisonProvider,
    StatComparisonProvider,
    OverviewComparisonProvider,
    FeaturedHeadToHeadProvider,
    ComparisonPerformanceChartProvider
} from '../../statspro/js/providers';
import FixtureBarProvider from '../../fixture-bar/js/providers/FixtureBarProvider';
import { PlayerStatsProvider } from '../../mc-player-stats/js/providers/PlayerStatsProvider';
import {
    BrownlowLiveTrackerProvider,
    BrownlowLeaderboardProvider,
    BrownlowLeaderboardEmbeddableProvider
} from '../../brownlow-tracker/js/providers';
import BrownlowRoundByRoundProvider from '../../brownlow-tracker/js/providers/BrownlowRoundByRoundProvider';
import BrownlowPlayerModalProvider from '../../brownlow-tracker/js/providers/BrownlowPlayerModalProvider';
import { DraftTrackerLeaderboardProvider } from '../../draft-tracker/js/providers';
import {
    VideoHubProvider,
    VideoHubViewByMatchProvider,
    VideoListProvider
} from '../../video-hub/js/providers';
import { FixturesProvider } from '../../fixtures/js/providers';
import { LadderProvider } from '../../ladder-v2/js/providers';
import { ProspectCareerAndSeasonStatsProvider } from '../../draft/js/providers/ProspectCareerAndSeasonStatsProvider';
import { DraftProspectBioProvider } from '../../draft/js/providers';
import { BroadcastGuideProvider } from '../../broadcast-guide/js/providers/BroadcastGuideProvider';
import { MainMenuRegionalDisplayProvider } from 'widgets/main-navigation/js/providers/MainMenuRegionalDisplayProvider';
import { RegionSelectionOptionsProvider } from 'widgets/region-selector/js/provider/RegionSelectionOptionsProvider';
import { SelectionCalloutProvider } from 'widgets/selection-callout/js/provider/SelectionCalloutProvider';
import { InformationSideBarProvider } from 'widgets/interactive-event-map/js/provider/InformationSideBarProvider';
import { MapLocationSelectorProvider } from 'widgets/interactive-event-map-small/js/provider/MapLocationSelectorProvider';
import { InformationLocationPanelProvider } from 'widgets/interactive-event-map-small/js/provider/InformationPanelProvider';
import { EventsListingProvider } from 'widgets/events-listing/js/providers';
import { FtdEventsListingProvider } from 'widgets/events-listing/js/providers';
import { MapSponsorProvider } from 'widgets/interactive-event-map/js/provider/MapSponsorProvider';
import { SiteSettingsModalProvider } from 'common/react/components/site-settings-modal/js/provider/SiteSettingsModalProvider';
import { TeamAnnouncementsProvider } from 'widgets/team-announcements/js/providers/TeamAnnouncementsProvider';
import { TASponsorProvider } from 'widgets/team-announcements/js/providers/TASponsorProvider';

(function (app) {
    /**
     * renderMap will contain a mapping of React "Provider" components to DOM selectors.
     * The React "component" will be rendered into any DOM elements matching the "node" selector.
     */
    const renderMap = [
        {
            node: '.js-react-default-table-provider',
            component: DefaultTableProvider
        },
        {
            node: '.js-react-default-table-modal-provider',
            component: DefaultTableModalProvider
        },
        {
            node: '.js-react-custom-ladder-provider',
            component: LadderTableProvider
        },
        {
            node: '.js-react-custom-rankings-provider',
            component: RankingsTableProvider
        },
        {
            node: '.js-react-player-stats-table-provider',
            component: PlayerStatsTableProvider
        },
        {
            node: '.js-react-statspro-nav-provider',
            component: StatsProHeaderProvider
        },
        {
            node: '.js-react-statspro-statsleaders-provider',
            component: StatsLeadersProvider
        },
        {
            node: '.js-react-statspro-glossary-provider',
            component: GlossaryProvider
        },
        {
            node: '.js-react-statspro-seasonleaders-provider',
            component: SeasonLeadersProvider
        },
        {
            node: '.js-react-fixture-bar-provider',
            component: FixtureBarProvider
        },
        {
            node: '.js-react-draft-prospect-career-season-provider',
            component: ProspectCareerAndSeasonStatsProvider
        },
        {
            node: '.js-react-statspro-lastroundhighs-provider',
            component: LastRoundHighsProvider
        },
        {
            node: '.js-react-statspro-career-season-provider',
            component: CareerAndSeasonStatsProvider
        },
        {
            node: '.js-react-statspro-player-profile-bio-provider',
            component: PlayerProfileBioProvider
        },
        {
            node: '.js-react-statspro-keystats-and-performance-provider',
            component: KeyStatsAndPerformanceProvider
        },
        {
            node: '.js-react-statspro-performance-chart-provider',
            component: PerformanceChartProvider
        },
        {
            node: '.js-react-overview-comparison-provider',
            component: OverviewComparisonProvider
        },
        {
            node: '.js-react-statspro-playercomparison-provider',
            component: PlayerComparisonProvider
        },
        {
            node: '.js-react-statspro-stat-comparison-provider',
            component: StatComparisonProvider
        },
        {
            node: '.js-react-statspro-featured-head-to-head-provider',
            component: FeaturedHeadToHeadProvider
        },
        {
            node: '.js-react-statspro-comparison-performance-chart-provider',
            component: ComparisonPerformanceChartProvider
        },
        {
            node: '.js-react-player-stats-provider',
            component: PlayerStatsProvider
        },
        {
            node: '.js-brownlow-live-tracker',
            component: BrownlowLiveTrackerProvider
        },
        {
            node: '.js-brownlow-leaderboard',
            component: BrownlowLeaderboardProvider
        },
        {
            node: '.js-react-brownlow-round-by-round',
            component: BrownlowRoundByRoundProvider
        },
        {
            node: '.js-react-brownlow-player-modal',
            component: BrownlowPlayerModalProvider
        },
        {
            node: '.js-brownlow-leaderboard-embeddable',
            component: BrownlowLeaderboardEmbeddableProvider
        },
        {
            node: '.js-draft-tracker-leaderboard',
            component: DraftTrackerLeaderboardProvider
        },
        {
            node: '.js-react-video-hub',
            component: VideoHubProvider
        },
        {
            node: '.js-video-hub-view-by-match',
            component: VideoHubViewByMatchProvider
        },
        {
            node: '.js-video-hub-video-list',
            component: VideoListProvider
        },
        {
            node: '.js-react-fixtures',
            component: FixturesProvider
        },
        {
            node: '.js-react-ladder',
            component: LadderProvider
        },
        {
            node: '.js-react-draft-prospect-bio-provider',
            component: DraftProspectBioProvider
        },
        {
            node: '.js-broadcast-guide',
            component: BroadcastGuideProvider
        },
        {
            node: '.js-regional-display',
            component: MainMenuRegionalDisplayProvider
        },
        {
            node: '.js-regional-options-display',
            component: RegionSelectionOptionsProvider
        },
        {
            node: '.js-selection-callout',
            component: SelectionCalloutProvider
        },
        {
            node: '.js-interactive-map-information',
            component: InformationSideBarProvider
        },
        {
            node: '.js-interactive-map-location-selector',
            component: MapLocationSelectorProvider
        },
        {
            node: '.js-interactive-map-location-panel',
            component: InformationLocationPanelProvider
        },
        {
            node: '.js-events-listing',
            component: EventsListingProvider
        },
        {
            node: '.js-featured-events-listing',
            component: FtdEventsListingProvider
        },
        {
            node: '.js-interactive-event-map__sponsor-logo',
            component: MapSponsorProvider
        },
        {
            node: '.js-site-settings',
            component: SiteSettingsModalProvider
        },
        {
            node: '.js-team-announcements',
            component: TeamAnnouncementsProvider
        },
        {
            node: '.js-ta-sponsor',
            component: TASponsorProvider
        }
    ];

    /**
     * Filter a list of DOM nodes to those that exist on the page,
     * Get a reference to the Nodes and map them to a React component,
     * Create a React Portal for each Node (so we can render React components into the same React tree, but not the same DOM tree).
     *
     * history can be used to pass in a reference to the value of the `history/createBrowserHistory` package function,
     * in case we need a package like "connected-react-router" to sync router state with the redux store.
     *
     * @param { Array } renderMapArr
     * @param history
     */
    const render = (renderMapArr, history) => {
        const children = renderMapArr
            .filter((item) => document.querySelector(item.node))
            .map((item) =>
                [...document.querySelectorAll(item.node)].map((node) => ({
                    node,
                    component: item.component
                }))
            )
            .flat()
            .map((item) => {
                return createPortal(
                    // we could alternatively spread "data" here if we don't want to nest any attributes
                    createElement(item.component, {
                        store: app.redux.store,
                        history,
                        data: item.node.dataset
                    }),
                    item.node
                );
            });

        // if we have any matching DOM selectors, render the matching React components
        if (children.length) {
            const container = document.createElement('div');
            const root = createRoot(container);
            root.render(createElement(Fragment, null, children));
        }
    };

    // wait for common to load, then run the bootstrap process
    new Promise((resolve) => {
        window.isCommonLoaded === true
            ? resolve()
            : window.addEventListener(
                  app.common.CONSTANTS.EVENTS.SCRIPTS.COMMON.LOADED,
                  resolve
              );
    }).then(() => render(renderMap));
})(PULSE.app);
