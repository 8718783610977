import React from 'react';
import PropTypes from 'prop-types';
import { StatsCard } from '../../common/StatsCard';
import { getTranslation } from 'common/react/utils/translations';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { ADD_PLAYER } from 'store/modules/statspro/player-comparison/actions';
import { useDispatch } from 'react-redux';
import { FeaturedHeadToHeadCardTop } from './FeaturedHeadToHeadCardTop';
import { FeaturedHeadToHeadCardStats } from './FeaturedHeadToHeadCardStats';
import { navLink } from '../../../utils/nav-link';

/**
 * @param {object} props - Props.
 * @param {Array} props.players - Players details.
 * @param {string} props.position - Players position.
 * @param props.showBenchmarkingAflw
 * @param {boolean} props.isAFLW - if this is an AFLW card
 * @returns {JSX.Element} Component.
 */
export const FeaturedHeadToHeadCard = ({
    players,
    position,
    showBenchmarkingAflw,
    isAFLW = false
}) => {
    const dispatch = useDispatch();
    const linkToFullComparison = () => {
        dispatch(ADD_PLAYER.request({ id: players[0].playerId, key: 1 }));
        dispatch(ADD_PLAYER.request({ id: players[1].playerId, key: 2 }));

        scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        navLink('compare');
    };

    return (
        <StatsCard>
            <FeaturedHeadToHeadCardTop
                players={players}
                position={position}
                isAFLW={isAFLW}
            />

            <div className="stats-card-table">
                {players.map((player, i) => (
                    <div
                        className="stats-card-table__player-name-container"
                        key={i}
                    >
                        <a
                            href={PULSE.app.common.url.getPlayerProfileURL(
                                player.playerId,
                                isAFLW
                            )}
                            className="stats-card-table__player-link"
                        >
                            <div className="stats-card-table__player-name">
                                {player.playerDetails.givenName}{' '}
                                <span className="stats-card-table__surname">
                                    {player.playerDetails.surname}
                                </span>
                            </div>
                        </a>
                    </div>
                ))}

                <FeaturedHeadToHeadCardStats
                    players={players}
                    position={position}
                    showBenchmarkingAflw={showBenchmarkingAflw}
                />

                <div className="stats-card-table__link-container">
                    <button
                        className="stats-card-table__link"
                        onClick={() => linkToFullComparison()}
                    >
                        {getTranslation('label.stats.fullComparison')}
                        <span className="stats-card-table__link-icon">
                            <SvgIcon icon="arrow-right" className="icon" />
                        </span>
                    </button>
                </div>
            </div>
        </StatsCard>
    );
};

FeaturedHeadToHeadCard.propTypes = {
    players: PropTypes.array.isRequired,
    position: PropTypes.string.isRequired,
    showBenchmarkingAflw: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    isAFLW: PropTypes.bool
};
