import React, { createRef, useEffect, useMemo } from 'react';
import { Scrollspy } from '@makotot/ghostui';

import { Modal } from './components/Modal';
import { Nav } from './components/Nav';
import { GlossaryTable } from './components/GlossaryTable';

import { useDispatch, useSelector } from 'react-redux';
import {
    statsGlossaryTermsSelector,
    statsGlossaryVisibleSelector
} from 'store/modules/statspro/glossary/selectors';
import {
    SETUP_GLOSSARY,
    CHANGE_GLOSSARY_MODAL_VISIBILITY
} from 'store/modules/statspro/glossary/actions';
import { getTranslation } from 'common/react/utils/translations';

const ALPHABET_CHARACTERS = 26;
const ASCII_OFFSET = 65;

export const Glossary = () => {
    const dispatch = useDispatch();
    const { glossary, modalVisible } = useSelector((state) => ({
        // @ts-ignore
        glossary: statsGlossaryTermsSelector(state),
        modalVisible: statsGlossaryVisibleSelector(state)
    }));

    const changeModalVisibility = (isVisible) => {
        return dispatch(CHANGE_GLOSSARY_MODAL_VISIBILITY.request(isVisible));
    };

    const setupGlossary = () => {
        return dispatch(SETUP_GLOSSARY.request());
    };

    useEffect(() => {
        if (_.isEmpty(glossary)) {
            setupGlossary();
        }
    }, [glossary]);

    const alphabet = useMemo(
        () =>
            Array.from(Array(ALPHABET_CHARACTERS)).map((value, i) =>
                String.fromCharCode(i + ASCII_OFFSET)
            ),
        []
    );

    const sectionRefs = useMemo(
        () =>
            alphabet.reduce((refs, key) => {
                return {
                    ...refs,
                    [key]: React.createRef()
                };
            }, {}),
        [alphabet]
    );
    const glossaryContainerRef = createRef();

    const onNavItemClick = (event, key) => {
        event.preventDefault();

        if (sectionRefs[key]?.current && glossaryContainerRef?.current) {
            glossaryContainerRef.current.scrollTo({
                top:
                    sectionRefs[key].current.getBoundingClientRect().top +
                    glossaryContainerRef.current.scrollTop -
                    glossaryContainerRef.current.getBoundingClientRect().top +
                    1,
                behavior: 'smooth'
            });
        }
    };

    return Object.keys(glossary).length && modalVisible ? (
        <div className="stats-glossary">
            <Modal
                icon="info-circle"
                title={getTranslation('label.statspro.glossary.modal.title')}
                isVisible={modalVisible}
                hideModal={() => changeModalVisibility(false)}
            >
                <Scrollspy
                    rootSelector=".stats-glossary__items-container"
                    sectionRefs={Object.keys(glossary)
                        .sort()
                        .map((key) => sectionRefs[key])}
                >
                    {({ currentElementIndexInViewport }) => (
                        <>
                            <Nav
                                navItems={alphabet}
                                currentElementIndex={
                                    currentElementIndexInViewport
                                }
                                glossary={glossary}
                                onNavItemClick={onNavItemClick}
                            />
                            <div
                                ref={glossaryContainerRef}
                                className="stats-glossary__items-container"
                                tabIndex="0"
                            >
                                <GlossaryTable
                                    glossary={glossary}
                                    sectionRefs={sectionRefs}
                                />
                            </div>
                        </>
                    )}
                </Scrollspy>
            </Modal>
        </div>
    ) : (
        <></>
    );
};
