import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import { StatNavItem, SeasonNavItem } from './components/nav-items';
import { Heading, DataTypeToggle } from './components/header';
import { PerformanceChartContainer } from './components/chart';

import { usePerformanceChartNavSync } from './hooks/use-performance-chart-nav-sync';
import { useCompetitionNavigationOptions } from 'common/react/hooks/use-competition-navigation-options';

import { performanceChartNavSelector } from 'store/modules/statspro/performance-chart/selectors';
import classNames from 'classnames';
import { playersSelector } from 'store/modules/statspro/player-comparison/selectors';

export const PerformanceChart = ({
    title,
    type,
    competitionId,
    competitionPid,
    compseasonPid
}) => {
    const nav = useSelector(performanceChartNavSelector);

    const options = useCompetitionNavigationOptions(nav);
    usePerformanceChartNavSync(options, competitionId, competitionPid);
    const playerId = new URLSearchParams(location.search).get('playerId');
    const playerIdsFromStore = useSelector(playersSelector);
    const ref = useRef();

    const { WOMENS } = PULSE.app.common.CONSTANTS.PROVIDER_IDS;
    const isAFLW = competitionPid === WOMENS;

    const playerIds = useMemo(
        () => (playerId ? { 1: playerId, 2: null } : playerIdsFromStore),
        [playerId, playerIdsFromStore]
    );

    return (
        <div
            className={classNames('fade-in-on-load', {
                'fade-in-on-load fade-in-on-load--stats comparison-chart__wrapper':
                    !playerId,
                'is-loaded': playerIds[1] || playerIds[2]
            })}
            ref={ref}
        >
            {playerIds[1] || playerIds[2] ? (
                <StatsSection.Main>
                    <StatsSection.Heading title={title} />
                    <StatsSection.Nav>
                        <StatsSection.NavItems>
                            <StatNavItem />
                            <SeasonNavItem />
                        </StatsSection.NavItems>
                    </StatsSection.Nav>
                    <StatsSection.Content>
                        <StatsSection.ContentHeader>
                            <StatsSection.ContentHeaderHeading>
                                <Heading />
                            </StatsSection.ContentHeaderHeading>
                            <StatsSection.ContentHeaderOptions>
                                <DataTypeToggle />
                            </StatsSection.ContentHeaderOptions>
                        </StatsSection.ContentHeader>
                        <StatsSection.ContentBody>
                            <PerformanceChartContainer
                                playerIds={playerIds}
                                type={type}
                                containerRef={ref}
                                isAFLW={isAFLW}
                                compseasonPid={compseasonPid}
                            />
                        </StatsSection.ContentBody>
                    </StatsSection.Content>
                </StatsSection.Main>
            ) : null}
        </div>
    );
};

PerformanceChart.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    competitionId: PropTypes.string,
    competitionPid: PropTypes.string,
    compseasonPid: PropTypes.string
};
