import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';

const cssClass = 'brownlow-tracker-leaderboard-mobile-table';

export const MobileTableHead = ({
    headerGroup,
    sortByFavourites,
    setSortByFavourites,
    skipColumns
}) => {

    const customCells = {
        player: (column) => (
            <div
                className={`${cssClass}__header-cell`}
                role="columnheader"
                key={column.id}
            >
                <div className="stats-table__header-cell-button">
                    <button
                        onClick={(event) => {
                                event.preventDefault();
                                setSortByFavourites(!sortByFavourites)
                            }
                        }
                        className="stats-table__header-cell-favourite-button"
                    >
                        {sortByFavourites ? (
                            <SvgIcon
                                icon="star-filled"
                                className="icon"
                            />
                        ) : (
                            <SvgIcon
                                icon="star-empty"
                                className="icon"
                            />
                        )}
                    </button>
                    {column.render('Header')}
                </div>
            </div>
        ),
        totalVotes: (column) => (
            <div
                className={`${cssClass}__header-cell`}
                role="columnheader"
                key={column.id}
            >
                <button
                    className="stats-table__header-cell-button"
                    disabled={true}
                >
                    {column.render('Header')}
                </button>
            </div>
        ),
        bettingOdds: (column) => (
            <div
                className={`${cssClass}__header-cell`}
                role="columnheader"
                key={column.id}
            >
                <button
                    className="stats-table__header-cell-button"
                    disabled={true}
                >
                    <img
                        className="stats-table__sportsbet-callout"
                        src={`/resources/${window.RESOURCES_VERSION}/i/elements/sportsbet-callout.svg`}
                    />
                </button>
            </div>
        )
    }

    const headers = headerGroup.headers.filter((column) => {
        if (!skipColumns) {
            return true;
        }
        return !skipColumns.includes(column.id);
    })

    return (
        // Fake thead Row
        <div
            {...headerGroup.getHeaderGroupProps()}
            className={`${cssClass}__header-row`}
        >
            {headers.map(column => {
                const cellContent = column.render('Header');
                const { onClick, ...headerProps } = column.getHeaderProps(
                    column.getSortByToggleProps()
                );

                if (customCells && customCells[column.id]) {
                    return customCells[column.id](
                        column
                    );
                }

            })}
        </div>
    )
}

MobileTableHead.propTypes = {
    headerGroup: PropTypes.object.isRequired,
    sortByFavourites: PropTypes.bool,
    setSortByFavourites: PropTypes.func,
    skipColumns: PropTypes.array
}