import React from 'react';
import PropTypes from 'prop-types';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import { BioStats } from './components/BioStats';
import { BioAwards } from './components/BioAwards';
import { useGetPlayerProfileQuery } from 'store/modules/api/statsPro-api';

/**
 * @param root0
 * @param root0.data
 * @param root0.seasonId
 * @returns {JSX.Element} Component.
 */
export const PlayerProfileBio = ({ seasonId }) => {
    const playerId = new URLSearchParams(location.search).get('playerId');
    const { data } = useGetPlayerProfileQuery({ seasonId, playerId });

    return data ? (
        <StatsSection.Main>
            <StatsSection.Heading
                title={PULSE.I18N.lookup('label.stats.playerProfile')}
            />
            <StatsSection.Content>
                <StatsSection.ContentHeader>
                    <StatsSection.ContentHeaderHeading cssClass="stats-section-content-header__heading--full-width">
                        <BioStats data={data} />
                    </StatsSection.ContentHeaderHeading>
                </StatsSection.ContentHeader>
                <StatsSection.ContentBody>
                    <BioAwards data={data} />
                </StatsSection.ContentBody>
            </StatsSection.Content>
        </StatsSection.Main>
    ) : (
        <></>
    );
};

PlayerProfileBio.propTypes = {
    seasonId: PropTypes.string.isRequired
};
