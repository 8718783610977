import React from 'react';
import PropTypes from 'prop-types';
import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import { BenchmarkingExplainer } from 'common/react/components/benchmarking';
import { KeyStatsAndPerformanceMain } from './components/KeyStatsAndPerformanceMain';
import {
    getTranslation,
    lookupTranslation
} from 'common/react/utils/translations';
import { EmptyState } from 'common/react/components/EmptyState';
import { Loader } from 'common/react/components/Loader';

import { useGetPlayerProfileQuery } from 'store/modules/api/statsPro-api';
import {
    useGetPlayerBestSeasonQuery,
    useGetPlayerBestCareerQuery
} from 'store/modules/api/cfs-api';

export const KeyStatsAndPerformance = ({
    compseasonPid,
    competitionPid,
    showBenchmarkingAflw
}) => {
    const playerId = new URLSearchParams(location.search).get('playerId');

    const showBenchmarkingAFLWBool = showBenchmarkingAflw === 'true';
    const { WOMENS } = PULSE.app.common.CONSTANTS.PROVIDER_IDS;
    const isAFLW = competitionPid === WOMENS;
    const showBenchmarking = !isAFLW || (isAFLW && showBenchmarkingAFLWBool);

    const {
        data: playerData,
        isFetching,
        isError
    } = useGetPlayerProfileQuery({ seasonId: compseasonPid, playerId });

    // Fetch top season performance data
    const { data: topSeasonData } = useGetPlayerBestSeasonQuery({
        playerId,
        competitionId: compseasonPid
    });

    // Fetch top career performance data
    const { data: topCareerData } = useGetPlayerBestCareerQuery({
        playerId,
        competitionId: competitionPid
    });

    const statsYear = compseasonPid.match(/S(\d{4})/)[1];

    return (
        <StatsSection.Main>
            <StatsSection.Content>
                <StatsSection.ContentHeader>
                    <StatsSection.ContentHeaderHeading>
                        <div className="key-stats-and-performance__header-text-wrapper">
                            <strong>
                                {playerData?.seasonTotals?.matchesPlayed ?? '-'}{' '}
                                {getTranslation('label.stats.matches')}
                            </strong>{' '}
                            {lookupTranslation(
                                'label.statsAndPerformance.forSeason',
                                {
                                    year: statsYear
                                }
                            )}
                        </div>
                        <span className="key-stats-and-performance__header-text-divider"></span>
                        <div className="key-stats-and-performance__header-text-wrapper">
                            <strong>
                                {playerData?.careerTotals?.matchesPlayed ?? '-'}{' '}
                                {getTranslation('label.stats.matches')}
                            </strong>{' '}
                            {getTranslation('label.stats.inCareer')}
                        </div>
                    </StatsSection.ContentHeaderHeading>
                    <StatsSection.ContentHeaderOptions>
                        {showBenchmarking ? <BenchmarkingExplainer /> : null}
                    </StatsSection.ContentHeaderOptions>
                </StatsSection.ContentHeader>
                <StatsSection.ContentBody>
                    {isFetching || isError ? (
                        <div className="key-stats-and-performance__loader">
                            <Loader />
                        </div>
                    ) : (
                        <>
                            {playerData.seasonAverages &&
                            playerData.careerAverages ? (
                                <KeyStatsAndPerformanceMain
                                    playerData={playerData}
                                    topSeasonData={topSeasonData}
                                    topCareerData={topCareerData}
                                    showBenchmarking={showBenchmarking}
                                />
                            ) : (
                                <div className="key-stats-and-performance__empty-state">
                                    <EmptyState />
                                </div>
                            )}
                        </>
                    )}
                </StatsSection.ContentBody>
            </StatsSection.Content>
        </StatsSection.Main>
    );
};

KeyStatsAndPerformance.propTypes = {
    compseasonPid: PropTypes.string.isRequired,
    competitionPid: PropTypes.string.isRequired,
    showBenchmarkingAflw: PropTypes.string
};
