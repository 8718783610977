import React from 'react';
import PropTypes from 'prop-types';

const CLASS_BLOCK = 'player-stats-tooltip-main-stat';

/**
 * @param {object} props - Props.
 * @param {object} props.row - Row.
 * @param {object} props.cell - Cell.
 * @returns {JSX.Element} Component.
 */
export const TooltipVotes = ({ cell }) => {
    return (
        <div className={CLASS_BLOCK}>
            <div className={`${CLASS_BLOCK}__name`}>
                Round {cell.column.id.replace('round', '')} votes
            </div>
            <div className={`${CLASS_BLOCK}__row`}>
                <div className={`${CLASS_BLOCK}__value`}>
                    {cell.value || '0'}
                </div>
            </div>
        </div>
    );
};

TooltipVotes.propTypes = {
    row: PropTypes.object,
    cell: PropTypes.object.isRequired
};
