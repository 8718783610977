import { createApi } from '@reduxjs/toolkit/query/react';
import { normalizeStatsLeaders } from '../statspro/stats-leaders/normalizr/normalize-stats-leaders';
import { fetchWithRetryBQ } from './base-queries/fetch-with-retry';

import { misBaseQuery } from './base-queries/mis-request';

// RTK Query update: Define an api service with a base URL and retry attempts
// RTK Query update: Endpoints can be added here or injected in components
export const statsProApi = createApi({
    reducerPath: 'statsProAPI',
    baseQuery: fetchWithRetryBQ(PULSE.app.environment.statsPro, misBaseQuery),
    endpoints: (build) => ({
        getPlayerProfile: build.query({
            query: ({ playerId, seasonId }) =>
                `/playerProfile/${playerId}?seasonId=${seasonId}`
        }),
        getLeadingPlayerStats: build.query({
            query: (seasonId) =>
                `/leadingPlayerStats/season/${seasonId}?limit=5`
        }),
        getLeadingPlayerMatchRoundTotals: build.query({
            query: (id) => `/leadingPlayerMatchTotals/round/${id}`
        }),
        getLeadingPlayerMatchSeasonTotals: build.query({
            query: (id) => `/leadingPlayerMatchTotals/season/${id}`
        }),
        getPlayerHeadToHead: build.query({
            query: (seasonId) =>
                `/playerHeadToHead/featured?seasonId=${seasonId}`
        }),
        getStatsLeaders: build.query({
            query: ({ id, type, benchmarking }) =>
                `/playersStats/${type}/${id}?includeBenchmarks=${!!benchmarking}`,
            transformResponse: (response) => {
                const { entities, result } = normalizeStatsLeaders(response);

                const statsIds = result.players;
                if (!statsIds) {
                    return null;
                }

                return statsIds.map((statsId) => {
                    const stats = entities.stats[statsId];

                    return {
                        stats,
                        details: {
                            playerId: stats.playerId,
                            ...entities.players[stats.playerId]
                        },
                        team: entities.teams[stats.team]
                    };
                });
            }
        }),
        getPlayerCareerSeasonStats: build.query({
            query: ({ isAFLW, playerId }) => {
                let queryString = `/playerCareerSeasonStats/${playerId}/benchmarked`;
                if (isAFLW) {
                    queryString += '?competitionType=AFLW';
                }
                return queryString;
            }
        }),
        getPlayerSeasonRoundStats: build.query({
            query: ([playerId, seasonId]) =>
                `/playerSeasonRoundStats/${playerId}?seasonId=${seasonId}`,
            transformResponse: (response) => {
                return {
                    [response.seasonId]: [...response.roundStats]
                };
            }
        }),
        getBenchmarkedPlayerSeasonRoundStats: build.query({
            query: ([playerId, seasonId]) =>
                `/playerSeasonRoundStats/${playerId}/benchmarked?seasonId=${seasonId}`,
            transformResponse: (response) => {
                return {
                    [response.seasonId]: [...response.roundStats]
                };
            }
        }),
        getSearchResults: build.query({
            query: ({ query, compseason, position = '', teamId = '' }) =>
                `/playersStats/seasons/${compseason}?playerNameLike=${query}&playerPosition=${position}&teamId=${teamId}`
        })
    })
});

export const {
    useGetPlayerProfileQuery,
    useGetLeadingPlayerStatsQuery,
    useGetLeadingPlayerMatchRoundTotalsQuery,
    useGetLeadingPlayerMatchSeasonTotalsQuery,
    useGetPlayerHeadToHeadQuery,
    useGetStatsLeadersQuery,
    useGetPlayerCareerSeasonStatsQuery,
    useGetBenchmarkedPlayerSeasonRoundStatsQuery,
    useGetPlayerSeasonRoundStatsQuery,
    useGetSearchResultsQuery
} = statsProApi;
