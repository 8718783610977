import { STATS_CATEGORY_IDS } from 'widgets/statspro/js/components/common/config/performance-stats';

export const composePlayerTwoSeasonLineData = (
    composedChartData,
    playerTwoSeasonArray,
    seasonArray,
    nav
) => {
    const playerTwoCumulatives = [];

    let composedData = composedChartData;

    playerTwoSeasonArray.forEach((item, index) => {
        if (item.error) {
            return null;
        }

        playerTwoCumulatives[index] =
            index > 0
                ? item.benchmarkedStats[STATS_CATEGORY_IDS[nav.category]]
                      .value + playerTwoCumulatives[index - 1]
                : item.benchmarkedStats[STATS_CATEGORY_IDS[nav.category]].value;

        let playerOneItem = composedData.find(
            (composedItem) => composedItem.name === item.roundNumber
        );

        if (playerOneItem) {
            playerOneItem.playerTwoValue = playerTwoCumulatives[index];
            playerOneItem.playerTwoSubtext = `${item.roundName}`;
        } else {
            composedData = [
                ...composedData,
                {
                    name: item.roundNumber ?? '',
                    playerTwoValue: playerTwoCumulatives[index],
                    subtext: item.roundName,
                    playerTwoSubtext: `${item.roundName}`
                }
            ];
        }
    });

    composedData.sort((a, b) => a.name - b.name);

    // Check for empty rounds in player two data and set it to the value from the previous round
    composedData.forEach((composedItem, index) => {
        if (!composedItem.playerOneValue) {
            composedItem.playerOneValue =
                composedData[index - 1]?.playerOneValue;
            composedItem.subtext = seasonArray.find(
                (item) => item.roundNumber === composedItem.name
            )?.roundName;
        } else if (!composedItem.playerTwoValue) {
            composedItem.playerTwoValue =
                composedData[index - 1]?.playerTwoValue;
            composedItem.playerTwoSubtext = seasonArray.find(
                (item) => item.roundNumber === composedItem.name
            )?.roundName;
        }
    });

    return composedData;
};
