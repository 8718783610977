/* eslint-disable complexity */
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Loader } from 'common/react/components/Loader';
import { getTranslation } from 'common/react/utils/translations';
import {
    performanceChartDataTypeSelector,
    performanceChartNavSelector
} from 'store/modules/statspro/performance-chart/selectors';
import { STATS_CATEGORY_IDS } from 'widgets/statspro/js/components/common/config/performance-stats';
import { EmptyState } from 'common/react/components/EmptyState';

import {
    ComparisonPlayerImages,
    PerformanceChartBarChart,
    PerformanceChartLineChart
} from './components';
import { useWindowWidth } from 'common/react/hooks/use-window-resize';
import { composeCareerData } from '../../utils';
import { composePlayerTwoCareerData } from '../../utils/compose-player-two-career-data';
import { composeSeasonBarData } from '../../utils/compose-season-bar-data';
import { composePlayerTwoSeasonBarData } from '../../utils/compose-player-two-season-bar-data';
import { composeSeasonLineData } from '../../utils/compose-season-line-data';
import { composePlayerTwoSeasonLineData } from '../../utils/compose-player-two-season-line-data';

import {
    useGetPlayerCareerSeasonStatsQuery,
    useGetBenchmarkedPlayerSeasonRoundStatsQuery,
    useGetPlayerProfileQuery
} from 'store/modules/api/statsPro-api';

export const PerformanceChartContainer = ({
    playerIds,
    type,
    containerRef,
    isAFLW,
    compseasonPid
}) => {
    const nav = useSelector(performanceChartNavSelector);
    const dataType = useSelector(performanceChartDataTypeSelector);
    const seasons = useSelector(
        (state) =>
            state.seasons[`competition_${nav.competition.id}`]?.list ?? []
    );
    const season = seasons.find((item) => item.id === nav.season.id);
    const seasonId = season?.providerId ?? compseasonPid;

    /**
     * Compare 2 compseasons ids. To be used as a comparator function inside of
     * an Array.prototype.sort call
     *
     * The special cases are to ensure that the extra '2022' season always comes after the 2023 season in the ordering
     * even though its ID values, the thing we sort by is higher than the 2023 season.
     *
     * @param {object} compSeasonA - A compseason object with a seasonId property
     * @param {object} compSeasonB - A compseason object with a seasonId property
     * @returns {number} - The return number consumed by .sort
     */
    const _compareCompSeasonId = (compSeasonA, compSeasonB) => {
        if (
            compSeasonA.seasonId === 'CD_S2023264' &&
            compSeasonB.seasonId === 'CD_S2101264'
        ) {
            return 1; // "CD_S2101264" comes after "CD_S2023264"
        }
        if (
            compSeasonA.seasonId === 'CD_S2101264' &&
            compSeasonB.seasonId !== 'CD_S2023264'
        ) {
            return -1; // "CD_S2101264" comes before anything else
        }
        if (
            compSeasonA.seasonId !== 'CD_S2023264' &&
            compSeasonB.seasonId === 'CD_S2023264'
        ) {
            return 1; // "CD_S2023264" comes after anything else
        }
        if (
            compSeasonA.seasonId !== 'CD_S2023264' &&
            compSeasonB.seasonId === 'CD_S2101264'
        ) {
            return 1; // "CD_S2101264" comes after "CD_S2023264"
        }

        // Compare other season IDs normally
        return compSeasonA.seasonId > compSeasonB.seasonId
            ? -1
            : compSeasonA.seasonId < compSeasonB.seasonId
            ? 1
            : 0;
    };

    const { data: playerDetails } = useGetPlayerProfileQuery(
        {
            seasonId,
            playerId: playerIds[1]
        },
        { skip: !seasonId || !playerIds[1] }
    );
    const { data: playerTwoDetails } = useGetPlayerProfileQuery(
        {
            seasonId,
            playerId: playerIds[2]
        },
        { skip: !seasonId || !playerIds[2] }
    );

    const [chartData, setChartData] = useState([]);
    const [chartType, setChartType] = useState('bar');
    const [xLabel, setXLabel] = useState(getTranslation('label.stats.season'));
    const [yLabel, setYLabel] = useState(
        getTranslation(
            'label.performanceChart.' + STATS_CATEGORY_IDS[nav.category]
        )
    );
    const [hideTooltip, setHideTooltip] = useState(false);
    const windowWidth = useWindowWidth(500);

    const [isFetching, setIsFetching] = useState(false);
    const [isError, setIsError] = useState(false);

    // 4 rtk query requests for each data set
    const {
        data: player1CareerStats,
        isFetching: player1CareerIsFetching,
        isError: player1CareerIsError
    } = useGetPlayerCareerSeasonStatsQuery(
        {
            isAFLW,
            playerId: playerIds[1]
        },
        {
            skip: !playerIds[1]
        }
    );

    const {
        data: player2CareerStats,
        isFetching: player2CareerIsFetching,
        isError: player2CareerIsError
    } = useGetPlayerCareerSeasonStatsQuery(
        {
            isAFLW,
            playerId: playerIds[2]
        },
        {
            skip: !playerIds[2]
        }
    );

    const {
        data: player1SeasonStats,
        isFetching: player1SeasonIsFetching,
        isError: player1SeasonIsError
    } = useGetBenchmarkedPlayerSeasonRoundStatsQuery([playerIds[1], seasonId], {
        skip: !seasonId
    });

    const {
        data: player2SeasonStats,
        isFetching: player2SeasonIsFetching,
        isError: player2SeasonIsError
    } = useGetBenchmarkedPlayerSeasonRoundStatsQuery([playerIds[2], seasonId], {
        skip: !playerIds[2] || !seasonId
    });

    // This layer of processing is required to ensure that if a player is removed from the comparison widget
    // we do not pass the previously selected players data, it effectively resets the table.
    const playerDetailsSelected =
        playerIds?.[1] && playerIds?.[1] === playerDetails?.playerId
            ? playerDetails
            : null;
    const playerTwoDetailsSelected =
        playerIds?.[2] && playerIds?.[2] === playerTwoDetails?.playerId
            ? playerTwoDetails
            : null;

    const player1CareerStatsSelected =
        playerIds?.[1] && playerIds?.[1] === player1CareerStats?.playerId
            ? player1CareerStats
            : null;
    const player2CareerStatsSelected =
        playerIds?.[2] && playerIds?.[2] === player2CareerStats?.playerId
            ? player2CareerStats
            : null;

    const player1SeasonStatsSelected = playerIds[1] ? player1SeasonStats : null;
    const player2SeasonStatsSelected = playerIds[2] ? player2SeasonStats : null;

    const playerData = useMemo(
        () => ({
            yearly: player1CareerStatsSelected
                ? player1CareerStatsSelected.yearlySeasonStats
                : [],
            season: player1SeasonStatsSelected ? player1SeasonStatsSelected : {}
        }),
        [player1CareerStatsSelected, player1SeasonStatsSelected]
    );

    const playerTwoData = useMemo(
        () => ({
            yearly: player2CareerStatsSelected
                ? player2CareerStatsSelected.yearlySeasonStats
                : [],
            season: player2SeasonStatsSelected ? player2SeasonStatsSelected : {}
        }),
        [player2CareerStatsSelected, player2SeasonStatsSelected]
    );

    // check if fetching
    useEffect(() => {
        if (
            player1CareerIsFetching ||
            player2CareerIsFetching ||
            player1SeasonIsFetching ||
            player2SeasonIsFetching
        ) {
            setIsFetching(true);
        } else {
            setIsFetching(false);
        }
    }, [
        player1CareerIsFetching,
        player2CareerIsFetching,
        player1SeasonIsFetching,
        player2SeasonIsFetching
    ]);

    // check if error
    useEffect(() => {
        if (!playerIds[2] && (player1CareerIsError || player1SeasonIsError)) {
            setIsError(true);
            return;
        }

        if (nav.season.id === -1) {
            if (player1CareerIsError && player2CareerIsError) {
                setIsError(true);
                return;
            }
        } else if (player1SeasonIsError && player2SeasonIsError) {
            setIsError(true);
            return;
        }

        setIsError(false);
    }, [
        player1CareerIsError,
        player2CareerIsError,
        player1SeasonIsError,
        player2SeasonIsError
    ]);

    useEffect(() => {
        // update Stats Category label
        setYLabel(
            getTranslation(
                'label.performanceChart.' + STATS_CATEGORY_IDS[nav.category]
            )
        );
    }, [nav.category]);

    useEffect(() => {
        setXLabel(getTranslation('label.stats.season'));

        let composedChartData = [];

        // If Career is chosen we display..
        // Totals - Totals for each season
        // Per Game - N/A
        if (nav.season.id === -1) {
            if (!playerData.yearly?.length && !playerTwoData.yearly?.length) {
                setChartData([]);
                return;
            }

            if (dataType.type === 'totals') {
                setChartType('bar');

                composedChartData = composeCareerData(playerData, nav);

                // If there is data for player two, combine the data for both players
                composedChartData = composePlayerTwoCareerData(
                    composedChartData,
                    playerTwoData,
                    nav
                );

                composedChartData.sort(_compareCompSeasonId);

                setChartData(composedChartData);
            }
        }
        // If Season is chosen we display..
        // Per Game - Individual totals for each round in the season
        // Totals - Cumulative totals for each round in the season
        else if (nav.season.id && nav.season.id !== -1) {
            setXLabel(getTranslation('label.match.round'));

            let seasonArray = [];
            if (playerData.season[season.providerId]) {
                seasonArray = [
                    ...playerData.season[season.providerId]
                ].reverse();
            }

            let playerTwoSeasonArray = [];
            if (playerTwoData.season[season.providerId]) {
                playerTwoSeasonArray = [
                    ...playerTwoData.season[season.providerId]
                ].reverse();
            }

            if (dataType.type === 'perGame') {
                setChartType('bar');

                composedChartData = composeSeasonBarData(
                    seasonArray,
                    nav,
                    type
                );

                // If there is data for player two, combine the data for both players
                composedChartData = composePlayerTwoSeasonBarData(
                    composedChartData,
                    playerTwoSeasonArray,
                    nav
                );

                setChartData(composedChartData.sort((a, b) => a.name - b.name));
            } else if (dataType.type === 'totals') {
                setChartType('line');

                composedChartData = composeSeasonLineData(
                    seasonArray,
                    nav,
                    type
                );

                // If there is data for player two, combine the data for both players
                composedChartData = composePlayerTwoSeasonLineData(
                    composedChartData,
                    playerTwoSeasonArray,
                    seasonArray,
                    nav
                );

                setChartData(composedChartData);
            }
        }
    }, [playerData, playerTwoData, nav.season.id, dataType.type, nav.category]);

    // Listen for window resize in case the tooltip is open as it can cause layout issues
    // when changing the orientation
    useEffect(() => {
        // The buffer is to account for padding on desktop
        const buffer = 100;
        if (
            containerRef.current &&
            innerWidth > containerRef.current.offsetWidth + buffer
        ) {
            setHideTooltip(true);
        } else {
            setHideTooltip(false);
        }
    }, [windowWidth]);

    return (
        <div className="performance-chart__content">
            {(() => {
                if (isError) {
                    // Error handling for when there is no data
                    return (
                        <div className="performance-chart__empty-state">
                            <EmptyState
                                titleTranslation="label.stats.error.noStatsAvailable"
                                summaryTranslation="label.stats.error.noStatsAvailable.summary"
                            />
                        </div>
                    );
                } else if (!chartData.length || isFetching) {
                    // Loader for waiting for data
                    return (
                        <div className="performance-chart__loader">
                            <Loader />
                        </div>
                    );
                }

                if (chartData.length > 0) {
                    return (
                        <>
                            {type === 'comparison' && (
                                <ComparisonPlayerImages
                                    players={[
                                        playerDetailsSelected,
                                        playerTwoDetailsSelected
                                    ]}
                                />
                            )}
                            {chartType === 'bar' && (
                                <PerformanceChartBarChart
                                    data={chartData}
                                    xLabel={xLabel}
                                    yLabel={yLabel}
                                    playerDetails={playerDetailsSelected}
                                    playerTwoDetails={playerTwoDetailsSelected}
                                    type={type}
                                    hideTooltip={hideTooltip}
                                />
                            )}
                            {chartType === 'line' && (
                                <PerformanceChartLineChart
                                    data={chartData}
                                    xLabel={xLabel}
                                    yLabel={yLabel}
                                    playerDetails={playerDetailsSelected}
                                    playerTwoDetails={playerTwoDetailsSelected}
                                    type={type}
                                    hideTooltip={hideTooltip}
                                />
                            )}
                        </>
                    );
                }
            })()}
        </div>
    );
};

PerformanceChartContainer.propTypes = {
    playerIds: PropTypes.object.isRequired,
    type: PropTypes.string,
    containerRef: PropTypes.any,
    isAFLW: PropTypes.bool,
    compseasonPid: PropTypes.string
};
