import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Table } from './components/Table';
import { composeColumnData } from '../utils/compose-column-data';
import { composeRowData } from '../utils/compose-row-data';
import { Loader } from 'common/react/components/Loader';
import { EmptyState } from 'common/react/components/EmptyState';
import { useWindowWidth } from 'common/react/hooks/use-window-resize';
import { useGetDraftByYearQuery } from '../api/draft-tracker-api';
import { draftTrackerNavSelector } from 'store/modules/draft-tracker/leaderboard/selectors';

export const DraftTrackerLeaderboardTable = ({ year }) => {
    const nav = useSelector(draftTrackerNavSelector);
    const filter = nav?.filter;
    const [currentPick, setCurrentPick] = useState(0);
    const [currentPickInitial, setCurrentPickInitial] = useState(true);
    const [isLive, setIsLive] = useState(false);
    const windowWidth = useWindowWidth(500);
    const table = useRef();

    // RTK Query update: Get data with the API endpoints hook
    // RTK Query update: We need to wait for the token before fetching
    const token = useSelector(PULSE.app.redux.selectors.token.selectToken);
    const [dataPolling, setDataPolling] = useState(
        PULSE.app.common.CONSTANTS.MATCH_POLL_DELAY
    );
    const { data: draftData, isLoading } = useGetDraftByYearQuery(year, {
        pollingInterval: dataPolling,
        skip: !token ? true : false
    });

    const status = draftData?.status;

    // RTK Query update: Polling can be added as a param, and set to 0 to stop polling
    useEffect(() => {
        if (draftData?.selections?.length) {
            setIsLive(true);
            const lastPick = draftData?.selections.length
                ? draftData.selections[draftData.selections.length - 1]
                : null;
            if (lastPick?.fullName || lastPick?.passed) {
                setDataPolling(0);
            }
        }
    }, [draftData]);

    /**
     * Get columns.
     */
    const columns = useMemo(
        () => composeColumnData(windowWidth, isLive),
        [windowWidth, isLive]
    );

    /**
     * Get rows.
     */
    const data = useMemo(() => {
        let selections = [];

        if (draftData) {
            if (draftData?.selections?.length) {
                selections = draftData.selections;
            } else if (draftData?.prospects?.length) {
                selections = draftData.prospects;
            }
        }

        // filter by round
        if (nav.round !== -1) {
            selections = selections?.filter(
                (item) => item.roundNumber === nav.round
            );
        }

        // filter by club
        if (nav.club !== -1) {
            selections = selections?.filter((item) => item.teamId === nav.club);
        }

        // filter by search query
        if (filter !== '') {
            const queryWords = filter
                .split(' ')
                .map((query) => query.toLowerCase());

            selections = selections.filter((item) =>
                /** look through the query words and if any have a match
                 * against either the given or surname then include in the
                 * filter
                 */
                queryWords.some(
                    (query) =>
                        item.fullName
                            ?.split(' ')[0]
                            ?.toLowerCase()
                            .includes(query) ||
                        item.fullName
                            ?.split(' ')[1]
                            ?.toLowerCase()
                            .includes(query) ||
                        item.firstName?.toLowerCase().includes(query) ||
                        item.surname?.toLowerCase().includes(query)
                )
            );
        }

        // find the current pick number from the data
        const pickNumber = draftData?.selections?.find(
            (item) => !item.fullName && !item.passed
        )?.pickNumber;

        setCurrentPick(pickNumber);

        return selections?.map((item) =>
            composeRowData(item, pickNumber, isLive, year)
        );
    }, [draftData, nav, filter, isLive]);

    // Scroll to the current pick on page load
    useEffect(() => {
        if (currentPick && currentPickInitial && table.current) {
            setCurrentPickInitial(false);

            const minPicksForScroll = 5;
            if (currentPick > minPicksForScroll) {
                const navHeightOffset = 130;

                scrollTo({ top: 0 });
                scrollTo({
                    top:
                        table.current
                            .querySelector(
                                `tbody > tr:nth-child(${currentPick - 1})`
                            )
                            ?.getBoundingClientRect().top - navHeightOffset,
                    behavior: 'smooth'
                });
            }
        }
    }, [currentPick]);

    if (isLoading) {
        // Loader for waiting for data
        return (
            <div className="draft-tracker-leaderboard__loader">
                <Loader />
            </div>
        );
    } else if (
        !draftData?.selections?.length &&
        !draftData?.prospects?.length
    ) {
        // Error handling for when there is no data
        return (
            <div className="draft-tracker-leaderboard__empty-state">
                <EmptyState
                    titleTranslation="label.empty.state.noData.title"
                    summaryTranslation="label.empty.state.noData.summary"
                />
            </div>
        );
    }

    return (
        <div ref={table}>
            <Table
                key={`${currentPick}-${nav?.round}-${nav?.club}-${filter}`}
                columns={columns}
                data={data}
                modifier={`draft-tracker-leaderboard ${
                    !isLive ? 'is-prospects' : ''
                }`}
                status={status}
            />
        </div>
    );
};

DraftTrackerLeaderboardTable.propTypes = {
    year: PropTypes.string.isRequired
};
