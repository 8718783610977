import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';

const CLASS_BLOCK = 'player-stats-tooltip-link';

/**
 * @param {object} props - Props.
 * @param {object} props.player - Player.
 * @returns {JSX.Element} Component.
 */
export const TooltipLink = ({ player }) => {
    const playerProfileURL = PULSE.app.common.url.getPlayerProfileURL(
        player.playerId
    );

    return (
        <>
            <a
                href={playerProfileURL}
                className={`${CLASS_BLOCK} ${CLASS_BLOCK}--full`}
            >
                <div className={`${CLASS_BLOCK}__content`}>
                    <SvgIcon
                        className={`${CLASS_BLOCK}__icon`}
                        icon="person"
                        folder="elements"
                        subfolder="stats"
                    />
                    <span>{getTranslation('label.stats.playerProfile')}</span>
                </div>
            </a>
        </>
    );
};

TooltipLink.propTypes = {
    player: PropTypes.object.isRequired
};
