import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import { useCompetitionNavigationOptions } from 'common/react/hooks/use-competition-navigation-options';
import { useLeaderboardNavSync } from './hooks/use-leaderboard-nav-sync';
import { leaderboardNavSelector } from 'store/modules/brownlow-tracker/leaderboard/selectors';
import { ClubNavItem, SeasonNavItem, Search } from './components/nav-items';
import { LeaderboardTable } from './components/table';
import { SettingsButton } from 'common/react/components/SettingsButton';

export const Leaderboard = ({ seasonsToShow, seasonPid }) => {
    const nav = useSelector(leaderboardNavSelector);
    const options = useCompetitionNavigationOptions(nav);

    options.defaultSeason = seasonPid ?? seasonPid;
    options.seasonsToShow = seasonsToShow ?? seasonsToShow;
    useLeaderboardNavSync(options);

    const classPrefix = 'brownlow-tracker-leaderboard';

    return (
        <div className={classPrefix}>
            <StatsSection.Main>
                <StatsSection.Nav>
                    <StatsSection.NavItems>
                        <SeasonNavItem seasonsToShow={seasonsToShow} />
                        <ClubNavItem />
                        <div className={`${classPrefix}-nav__extras`}>
                            <Search />
                        </div>
                    </StatsSection.NavItems>
                    <StatsSection.SettingsButton>
                        <SettingsButton />
                    </StatsSection.SettingsButton>
                </StatsSection.Nav>
                <StatsSection.Content>
                    <StatsSection.ContentBody>
                        <LeaderboardTable cssClass={classPrefix} />
                    </StatsSection.ContentBody>
                </StatsSection.Content>
            </StatsSection.Main>
        </div>
    );
};

Leaderboard.propTypes = {
    seasonsToShow: PropTypes.string,
    seasonPid: PropTypes.string
};
