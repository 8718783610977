import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { CHANGE_PLAYER_SELECTION_MODAL_VISIBILITY } from 'store/modules/statspro/player-comparison/actions';
import { SvgIcon } from 'common/react/components/SvgIcon';

/**
 *
 * @param {*} param0
 * @returns
 */
export const Details = ({ cssClass, children, floatingIcon }) => {
    const dispatch = useDispatch();

    const onButtonClick = useCallback(() => {
        dispatch(
            CHANGE_PLAYER_SELECTION_MODAL_VISIBILITY.request({
                isVisible: true
            })
        );
    });

    return (
        <button
            className={`compare-header__details ${cssClass}`}
            onClick={onButtonClick}
        >
            {floatingIcon ? (
                <SvgIcon
                    className="compare-header__floating-icon"
                    icon={floatingIcon}
                    folder="elements"
                    subfolder="stats"
                />
            ) : null}
            <span className="compare-header__details-inner">{children}</span>
        </button>
    );
};

Details.propTypes = {
    cssClass: PropTypes.string,
    children: PropTypes.any,
    floatingIcon: PropTypes.string
};
