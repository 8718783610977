import React from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import { filtersSelector } from 'store/modules/competition-nav/selectors/filters-selector';
import { statsLeadersFiltersSelector } from 'store/modules/statspro/stats-leaders/selectors/filters-selector';
import { playerComparisonFiltersSelector } from 'store/modules/statspro/player-comparison/selectors/filters-selector';
import { filtersSelector as eventsFiltersSelector } from 'store/modules/events-listing/selectors';

import { getTranslation } from '../utils/translations';
import { SvgIcon } from './SvgIcon';

export const FilterButton = ({
    onChange,
    resetFilterBtnStyles,
    setResetFilterBtnStyles,
    areFilterOptionsVisible
}) => {
    const filters = useSelector(filtersSelector);
    const statsFilters = useSelector(statsLeadersFiltersSelector);
    const playersFilters = useSelector(playerComparisonFiltersSelector);
    const eventsFilters = useSelector(eventsFiltersSelector);

    const filterSelected =
        filters?.cities.length > 0 ||
        filters?.teams.length > 0 ||
        filters?.venues.length > 0 ||
        statsFilters?.teams.length > 0 ||
        statsFilters?.positions.length > 0 ||
        playersFilters?.positions.length > 0 ||
        playersFilters?.teams.length > 0 ||
        eventsFilters?.eventTypes.length > 0 ||
        eventsFilters?.teams.length > 0;

    return (
        <button
            className={classNames(
                'filter-button',
                {
                    'filter-button--active':
                        areFilterOptionsVisible &&
                        !filterSelected &&
                        !resetFilterBtnStyles
                },
                {
                    'filter-button--selected':
                        filterSelected && !resetFilterBtnStyles
                },
                {
                    'filter-button--selected-no-bar':
                        !areFilterOptionsVisible &&
                        filterSelected &&
                        !resetFilterBtnStyles
                }
            )}
            onClick={() => {
                onChange();
                setResetFilterBtnStyles(false);
            }}
        >
            <SvgIcon
                icon="filter-circle"
                className="filter-button__icon"
                folder="elements"
                subfolder="stats"
            />
            <SvgIcon
                icon="filter-circle-fill"
                className="filter-button__icon-active"
                folder="elements"
                subfolder="stats"
            />
            {getTranslation('label.statsLeaders.filters.toggleButtonText')}
        </button>
    );
};

FilterButton.propTypes = {
    onChange: PropTypes.func.isRequired,
    areFilterOptionsVisible: PropTypes.bool,
    resetFilterBtnStyles: PropTypes.bool,
    setResetFilterBtnStyles: PropTypes.func
};
