import { STATS_CATEGORY_IDS } from 'widgets/statspro/js/components/common/config/performance-stats';

export const composePlayerTwoSeasonBarData = (
    composedChartData,
    playerTwoSeasonArray,
    nav
) => {
    let composedData = composedChartData;

    playerTwoSeasonArray.forEach((item) => {
        if (item.error) {
            return null;
        }

        let playerOneItem = composedData.find(
            (composedItem) => composedItem.name === item.roundNumber
        );

        if (playerOneItem) {
            playerOneItem.playerTwoValue =
                item.benchmarkedStats[STATS_CATEGORY_IDS[nav.category]]?.value;
            playerOneItem.playerTwoSubtext = `${item.roundName}`;
            playerOneItem.playerTwoBenchmark =
                item.benchmarkedStats[STATS_CATEGORY_IDS[nav.category]]
                    ?.benchmark ?? '';
        } else {
            composedData = [
                ...composedData,
                {
                    name: item.roundNumber ?? '',
                    playerOneValue: null,
                    playerTwoValue:
                        item.benchmarkedStats[STATS_CATEGORY_IDS[nav.category]]
                            ?.value,
                    playerTwoBenchmark:
                        item.benchmarkedStats[STATS_CATEGORY_IDS[nav.category]]
                            ?.benchmark ?? '',
                    subtext: null,
                    playerTwoSubtext: `${item.roundName}`
                }
            ];
        }
    });

    return composedData;
};
