import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';
import { PlayerModalVotes } from './player-modal-votes';
import { UPDATE_FAVOURITE_PLAYER } from 'store/modules/brownlow-tracker/leaderboard/actions';
import { isFavouritePlayerSelector } from 'store/modules/brownlow-tracker/leaderboard/selectors';
import { playerModalSelector } from 'store/modules/brownlow-tracker/player-modal/selectors';

export const PlayerModalDetails = ({ playerId }) => {
    const dispatch = useDispatch();
    const modal = useSelector(playerModalSelector);
    const isFavourited = useSelector((state) =>
        isFavouritePlayerSelector(state, playerId)
    );

    // Link for the player profile button
    const playerProfileLink = () => {
        location = PULSE.app.common.url.getPlayerProfileURL(playerId);
    };

    const onPlayerFavouriteClick = () => {
        dispatch(
            UPDATE_FAVOURITE_PLAYER.request({
                id: null,
                providerId: playerId
            })
        );
    };

    return (
        <div className="brownlow-player-modal__player-details-container">
            <div className="brownlow-player-modal__player-details-mobile-background"></div>

            <div className="brownlow-player-modal__player-name-container">
                <span className="brownlow-player-modal__first-name">
                    {modal.player.givenName}
                </span>
                <span className="brownlow-player-modal__last-name">
                    {modal.player.surname}
                </span>
            </div>

            <div className="brownlow-player-modal__buttons-container">
                <button
                    className="brownlow-player-modal__button"
                    data-favourite-id=""
                    data-favourite-provider-id={playerId}
                    onClick={onPlayerFavouriteClick}
                >
                    {!playerId || !isFavourited ? (
                        <SvgIcon icon="star-outline" className="icon" />
                    ) : (
                        <SvgIcon icon="star-filled" className="icon" />
                    )}
                    {getTranslation('label.favourite')}
                </button>
                <button
                    className="brownlow-player-modal__button"
                    onClick={() => playerProfileLink()}
                >
                    <SvgIcon
                        icon="person"
                        folder="elements"
                        subfolder="stats"
                        className="icon"
                    />
                    {getTranslation('label.stats.playerProfile')}
                </button>
            </div>

            <PlayerModalVotes />
        </div>
    );
};

PlayerModalDetails.propTypes = {
    seasonPid: PropTypes.string.isRequired,
    playerId: PropTypes.string.isRequired
};
