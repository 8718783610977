import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { FavouriteButton } from 'widgets/brownlow-tracker/js/common/FavouriteButton';
import { getTranslation } from 'common/react/utils/translations';
import {
    leaderboardDataSelector,
    leaderboardNavSelector
} from 'store/modules/brownlow-tracker/leaderboard/selectors';
import { UPDATE_PLAYER_MODAL } from 'store/modules/brownlow-tracker/player-modal/actions';
import { PlayerPhoto } from 'common/react/components/PlayerPhoto';

export const MobilePlayerCell = ({
    player,
    onPlayerFavouriteClick,
    favourites,
    seasonPid,
    ...cellProps
}) => {
    const dispatch = useDispatch();
    const leaderboardData = useSelector((state) =>
        leaderboardDataSelector(state, seasonPid)
    );
    const nav = useSelector(leaderboardNavSelector);

    const status = leaderboardData?.status
        ? PULSE.app.common.match.getMatchStatus(leaderboardData.status)
        : '';

    // Activate the player modal
    const activatePlayerModal = (event) => {
        if (
            event.keyCode &&
            event.keyCode !== PULSE.app.common.CONSTANTS.KEY_CODES.ENTER &&
            event.keyCode !== PULSE.app.common.CONSTANTS.KEY_CODES.SPACE
        ) {
            return;
        }

        dispatch(
            UPDATE_PLAYER_MODAL.request({
                visible: true,
                playerId: player.playerId,
                seasonPid: seasonPid,
                seasonId: nav.season.id,
                player
            })
        );
    };

    return (
        <div {...cellProps} className="stats-table__row-header-cell">
            <div
                className="stats-table__row-brownlow-player-cell"
                role="rowheader"
                scope="row"
            >
                <FavouriteButton
                    player={player}
                    onPlayerFavouriteClick={onPlayerFavouriteClick}
                    favourites={favourites}
                />

                <PlayerPhoto
                    className="stats-leaders-table-player__headshot"
                    photoUrl={player?.photoURL}
                    alt={`${getTranslation('label.stats.headshot.alt')}`}
                    width="1"
                    height="1"
                />

                <div>
                    <button
                        onClick={(event) => activatePlayerModal(event)}
                        onKeyDown={(event) => activatePlayerModal(event)}
                        className="stats-table__row-brownlow-player-name"
                    >
                        <p>{player.givenName}</p>
                        <p>{player.surname}</p>
                    </button>

                    {!player.eligible && (
                        <span className="stats-table__row-brownlow-ineligible">
                            {getTranslation(
                                'label.brownlow.predictor.ineligible'
                            )}
                        </span>
                    )}
                </div>

                {status &&
                    status ===
                        PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED &&
                    player.winner && (
                        <img
                            className="stats-table__player-cell-brownlow-badge"
                            src={`/resources/${window.RESOURCES_VERSION}/i/elements/brownlow-gold.svg`}
                        />
                    )}
            </div>
        </div>
    );
};

MobilePlayerCell.propTypes = {
    player: PropTypes.object.isRequired,
    onPlayerFavouriteClick: PropTypes.func,
    favourites: PropTypes.array,
    seasonPid: PropTypes.string
};
