// eslint-disable no-lone-blocks

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useGetMatchRosterFullQuery } from 'common/store/modules/api/cfs-api';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { TAModeToggle } from './TAModeToggle';
import { TAEmpty } from './TAEmpty';
import { TASkeleton } from './TASkeleton';
import { TeamAnnouncementsContent } from './TeamAnnouncementsContent';

export const TeamAnnouncements = ({
    pid,
    teamDisplay,
    notAnnouncedTitle,
    notAnnouncedDescription
}) => {
    const providerId = pid;
    const initialTeamDisplay = teamDisplay;
    const [viewMode, setViewMode] = useState('field');

    // Fetch the match roster data using RTK query
    const { data: matchRosterData, isLoading } = useGetMatchRosterFullQuery({
        matchId: providerId
    });

    const displayHome =
        teamDisplay === 'home' ||
        teamDisplay === 'both' ||
        initialTeamDisplay === 'home' ||
        initialTeamDisplay === 'both';
    const displayAway =
        teamDisplay === 'away' ||
        teamDisplay === 'both' ||
        initialTeamDisplay === 'away' ||
        initialTeamDisplay === 'both';

    return (
        <>
            <div className="team-announcements__content-body">
                {isLoading ? (
                    <TASkeleton />
                ) : matchRosterData ? (
                    <>
                        <TAModeToggle
                            viewMode={viewMode}
                            setViewMode={setViewMode}
                        />
                        <TeamAnnouncementsContent
                            viewMode={viewMode}
                            matchRosterData={matchRosterData}
                            initialTeamDisplay={initialTeamDisplay}
                            displayHome={displayHome}
                            displayAway={displayAway}
                        />
                    </>
                ) : (
                    <TAEmpty
                        notAnnouncedTitle={notAnnouncedTitle}
                        notAnnouncedDescription={notAnnouncedDescription}
                    />
                )}
            </div>
            <div className="team-announcements__last-updated">
                <SvgIcon
                    icon="info-circle"
                    className="team-announcements__last-updated-icon"
                />
                <p className="team-announcements__last-updated-details">
                    <span className="team-announcements__last-updated-label">
                        {PULSE.I18N.lookup('label.teamlineups.lastUpdated')}
                    </span>{' '}
                    <span className="team-announcements__last-updated-time">
                        {moment(
                            matchRosterData?.matchRoster?.lastUpdated
                        ).format('h:mm a dddd, MMMM DD, YYYY')}
                    </span>
                </p>
            </div>
        </>
    );
};

TeamAnnouncements.propTypes = {
    pid: PropTypes.string,
    teamDisplay: PropTypes.string,
    notAnnouncedTitle: PropTypes.string,
    notAnnouncedDescription: PropTypes.string
};
