import React, { useEffect, useState } from 'react';

import { InformationCategoryHeader } from './InformationCategoryHeader';
import { InformationCard } from './InformationCard';
import { SvgIcon } from 'common/react/components/SvgIcon';

import { useCupertinoPane } from '../hooks';
import { InformationBottomDrawer } from './InformationBottomDrawer';
import { MapLocationSelector } from './MapLocationSelector';
import { getCategory, filterItems } from '../util';

export const InformationSideBar = (data) => {
    const mapContainer = document.querySelector('.interactive-event-map');
    const startingVenue = {
        name: mapContainer.dataset.startingVenue,
        id: mapContainer.dataset.startingVenueId
    };
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [googlePlacesData, setGooglePlacesData] = useState(null);
    const [selectedVenue, setSelectedVenue] = useState({
        name: startingVenue.name,
        imgRef: null
    });
    const [locations, setLocations] = useState(PULSE.mapPins);
    const isMobile = window.matchMedia(
        `(max-width: ${PULSE.app.measurements.desktop}px)`
    );

    useEffect(() => {
        let additionalLocationsArray = [];

        if (selectedVenue.name === startingVenue.name) {
            setLocations(PULSE.mapPins);
        }

        PULSE.additionalLocations.forEach((additionalVenue) => {
            if (selectedVenue.name === additionalVenue.title) {
                additionalVenue.items.forEach((venueMarker) => {
                    additionalLocationsArray.push(venueMarker.response);
                });

                setLocations(additionalLocationsArray);
            }
        });
    }, [selectedVenue]);

    useEffect(() => {
        const updateStorageHandle = () => {
            setSelectedCategory(
                PULSE.core.localStorage.getStorage('mapSelectedCategory') ||
                    null
            );

            setSelectedLocation(
                JSON.parse(
                    PULSE.core.localStorage.getStorage('mapSelectedMarker')
                ) || null
            );

            setGooglePlacesData(
                JSON.parse(
                    PULSE.core.localStorage.getStorage(
                        'mapSelectedMarkerGoogleData'
                    )
                ) || null
            );
        };

        window.addEventListener('storageChange', updateStorageHandle);

        return () => {
            window.removeEventListener('storageChange', updateStorageHandle);
        };
    }, []);

    useCupertinoPane();

    return (
        <>
            {isMobile.matches ? (
                <InformationBottomDrawer
                    selectedCategory={selectedCategory}
                    googlePlacesData={googlePlacesData}
                    selectedLocation={selectedLocation}
                    locations={locations}
                    timezone={data.eventTimezone}
                />
            ) : (
                <>
                    {selectedCategory === null &&
                    selectedLocation === null &&
                    PULSE.additionalLocations.length ? (
                        <MapLocationSelector setVenue={setSelectedVenue} />
                    ) : null}

                    <div className="interactive-event-map__desktop-view">
                        <InformationCard
                            selectedLocation={selectedLocation}
                            googlePlacesData={googlePlacesData}
                            timezone={data.eventTimezone}
                        />

                        <InformationCategoryHeader
                            selectedCategory={selectedCategory}
                            selectedLocation={selectedLocation}
                        />

                        <ul
                            className={`interactive-event-map__information-list js-interactive-event-map__information-list ${
                                selectedLocation !== null ? 'u-hide' : ''
                            }`}
                        >
                            {locations.map((location) => (
                                <li
                                    className={`interactive-event-map__information-item js-interactive-event-map__information-item ${filterItems(
                                        getCategory(location).category,
                                        selectedCategory
                                    )}`}
                                    key={location.id}
                                    data-item-id={location.id}
                                >
                                    <div
                                        className={`interactive-event-map__information-item-icon interactive-event-map__information-item-icon--${
                                            getCategory(location).category
                                        } `}
                                    >
                                        <SvgIcon
                                            icon={
                                                getCategory(location).filterIcon
                                            }
                                            className="icon"
                                            folder="elements"
                                            subfolder="events-and-locations"
                                        />
                                    </div>
                                    <div className="interactive-event-map__information-item-copy">
                                        {location.title && (
                                            <h3 className="interactive-event-map__information-item-title">
                                                {location.title}
                                            </h3>
                                        )}
                                        {location.metadata?.map_event_type && (
                                            <p className="interactive-event-map__information-item-description">
                                                {
                                                    location.metadata
                                                        ?.map_event_type
                                                }
                                            </p>
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            )}
        </>
    );
};
