/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import {
    ResponsiveContainer,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    LineChart,
    Line
} from 'recharts';
import { performanceChartNavSelector } from 'store/modules/statspro/performance-chart/selectors';
import { ComparisonTooltip, CustomTooltip } from '../components';

export const PerformanceChartLineChart = ({
    data,
    xLabel,
    yLabel,
    playerDetails,
    playerTwoDetails,
    type,
    hideTooltip
}) => {
    const nav = useSelector(performanceChartNavSelector);

    let yLabelOptions = {},
        chartMargin = {};

    if (isMobile) {
        yLabelOptions = {
            value: yLabel,
            position: 'insideTopLeft',
            fontWeight: 500,
            dy: -35,
            dx: 20
        };
        chartMargin = {
            top: 50,
            bottom: 40,
            right: 10
        };
    } else {
        yLabelOptions = {
            value: yLabel,
            angle: -90,
            position: 'insideLeft',
            fontWeight: 500,
            dx: -10
        };
        chartMargin = {
            top: 40,
            bottom: 40,
            left: 30,
            right: 12
        };
    }

    return (
        <ResponsiveContainer width={isMobile ? '100%' : '98%'} height={500}>
            <LineChart data={data} margin={chartMargin} key={Math.random()}>
                <CartesianGrid vertical={false} stroke="#00000014" />
                <XAxis
                    dataKey="name"
                    label={{
                        value: xLabel,
                        position: 'outsideCenter',
                        fontWeight: 500,
                        dy: 20
                    }}
                    tickLine={false}
                    stroke="#00000014"
                    tick={{ fill: '#000' }}
                />
                <YAxis
                    label={yLabelOptions}
                    axisLine={false}
                    tickLine={false}
                    tickCount={10}
                    tick={{ fill: '#000' }}
                    allowDecimals={false}
                    type="number"
                />
                {playerDetails && !hideTooltip && (
                    <Tooltip
                        cursor={{ fill: 'transparent' }}
                        content={
                            type !== 'comparison' ? (
                                <CustomTooltip />
                            ) : (
                                <ComparisonTooltip />
                            )
                        }
                        player={playerDetails}
                        playerTwo={playerTwoDetails}
                        stat={nav.category}
                    />
                )}

                <Line
                    dataKey="playerOneValue"
                    strokeWidth={2}
                    stroke={
                        type !== 'comparison'
                            ? PULSE.app.common.statsMap.colours.DEFAULT
                            : PULSE.app.common.statsMap.colours.PLAYER_ONE
                    }
                    dot={{ r: 4 }}
                    activeDot={{ r: 8 }}
                    name="playerOneLine"
                    connectNulls={true}
                />
                <Line
                    dataKey="playerTwoValue"
                    strokeWidth={2}
                    stroke={PULSE.app.common.statsMap.colours.PLAYER_TWO}
                    dot={{ r: 4 }}
                    activeDot={{ r: 8 }}
                    name="playerTwoLine"
                    connectNulls={true}
                />
            </LineChart>
        </ResponsiveContainer>
    );
};
