import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';

export const ShowMoreButton = ({
    canNextPage,
    pageSize,
    currentPageSize,
    totalRowCount,
    onClick
}) => {
    if (!canNextPage) {
        return <></>;
    }

    const pagesToLoadCount = Math.min(
        pageSize,
        totalRowCount - currentPageSize
    );

    return (
        <button
            className="stats-table-load-more-button"
            onClick={() => {
                if (!canNextPage) {
                    return;
                }
                onClick();
            }}
        >
            <span>
                {getTranslation('label.statsLeaders.paginationNext').replace(
                    '{0}',
                    pagesToLoadCount
                )}
            </span>
            <SvgIcon
                className="icon stats-table-load-more-button__icon"
                icon="arrow-right"
            />
        </button>
    );
};

ShowMoreButton.propTypes = {
    canNextPage: PropTypes.bool.isRequired,
    pageSize: PropTypes.number.isRequired,
    currentPageSize: PropTypes.number.isRequired,
    totalRowCount: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired
};
