import React from 'react';
import PropTypes from 'prop-types';

import {
    STATS_COLUMNS,
    STATS_COLUMN_IDS
} from 'widgets/statspro/js/components/common/config/columns';

const STATS_TO_SHOW = [
    STATS_COLUMN_IDS.AFL_FANTASY_POINTS,
    STATS_COLUMN_IDS.DISPOSALS,
    STATS_COLUMN_IDS.GOALS,
    STATS_COLUMN_IDS.CLEARANCES
];

const CLASS_BLOCK = 'player-stats-tooltip-related-stats';

/**
 * @param {object} props - Props.
 * @param {object} props.row - Row.
 * @param {object} props.cell - Cell.
 * @param {object} props.player - Player.
 * @param {object} [props.benchmark] - Benchmark.
 * @returns {JSX.Element} Component.
 */
export const TooltipStats = (props) => {
    // const stat = props.cell.column.id;
    const roundNumber = props.cell.column.id.replace('round', '');

    const foundRound = props.rounds.roundStats.find((round) => {
        return round.roundNumber === parseInt(roundNumber);
    });

    return (
        <ul className={CLASS_BLOCK}>
            {STATS_TO_SHOW.map((stat) => {
                const statValue = foundRound?.stats[stat]
                    ? `${foundRound?.stats[stat]}`
                    : '-';
                return (
                    <StatListItem
                        key={stat}
                        name={STATS_COLUMNS[stat].name}
                        value={statValue.value ?? statValue}
                    />
                );
            })}
        </ul>
    );
};

TooltipStats.propTypes = {
    row: PropTypes.object.isRequired,
    cell: PropTypes.object.isRequired,
    player: PropTypes.object.isRequired,
    rounds: PropTypes.object.isRequired
};

const StatListItem = ({ name, value }) => {
    return (
        <li className={`${CLASS_BLOCK}__item`}>
            <span className={`${CLASS_BLOCK}__name`}>{name}</span>
            <span className={`${CLASS_BLOCK}__value`}>{value}</span>
        </li>
    );
};

StatListItem.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
};
