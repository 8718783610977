/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { BENCHMARKING } from 'common/react/components/benchmarking/config/benchmarking';
import { TooltipButton } from 'common/react/components/tooltip-button';
export const StatsTableBody = ({
    rows,
    getTableBodyProps,
    prepareRow,
    getBenchmark,
    primaryTooltipComponent,
    getCellId,
    getCellTitle,
    customCells,
    skipCells,
    valueWrapper,
    isProspect,
    // eslint-disable-next-line no-empty-function
    getTrProps = () => {}
}) => {
    const isProspectTable = isProspect ?? false;

    return (
        <tbody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
                prepareRow(row);

                const isQualiferRow =
                    rowIndex === row?.original?.qualiferRow
                        ? 'is-qualifier-row'
                        : '';

                const isConferenceRow =
                    row?.original?.isConference && rowIndex === 1
                        ? 'is-conference-row'
                        : '';

                const isLiveRow = row?.values?.isPlaying ? 'is-live' : '';

                const cells = row.cells.filter((cell) => {
                    if (!skipCells) {
                        return true;
                    }
                    return !skipCells.includes(cell.column.id);
                });

                return (
                    <tr
                        {...row.getRowProps()}
                        className={`stats-table__body-row ${isQualiferRow} ${isConferenceRow} ${isLiveRow}`}
                        {...getTrProps(row)}
                        key={rowIndex}
                    >
                        {cells.map((cell, cellIndex) => {
                            const cellProps = cell.getCellProps();

                            if (customCells && customCells[cell.column.id]) {
                                return customCells[cell.column.id](
                                    row,
                                    cell,
                                    cellProps,
                                    rowIndex
                                );
                            }

                            const benchmark = getBenchmark
                                ? getBenchmark(row, cell)
                                : null;
                            const value = cell.value ?? 0;

                            if (
                                typeof cell.value === 'undefined' &&
                                isProspectTable
                            ) {
                                return null;
                            }

                            return (
                                <td
                                    {...cellProps}
                                    className={classnames('stats-table__cell', {
                                        'stats-table__cell--sorted':
                                            cell.column.isSorted,
                                        'stats-table__cell--elite':
                                            benchmark ===
                                            BENCHMARKING.ELITE.KEY,
                                        'stats-table__cell--above-avg':
                                            benchmark ===
                                            BENCHMARKING.ABOVE_AVERAGE.KEY,
                                        'stats-table__cell--avg':
                                            benchmark ===
                                            BENCHMARKING.AVERAGE.KEY,
                                        'stats-table__cell--below-avg':
                                            benchmark ===
                                            BENCHMARKING.BELOW_AVERAGE.KEY
                                    })}
                                    key={cellIndex}
                                >
                                    <ConditionalWrapper
                                        condition={valueWrapper}
                                        wrapper={(children) => (
                                            <div
                                                className={classnames(
                                                    'stats-table__cell-inner-wrapper',
                                                    {
                                                        'stats-table__cell-inner-wrapper--empty':
                                                            !value
                                                    },
                                                    {
                                                        'stats-table__cell-inner-wrapper--bye':
                                                            value === 'B'
                                                    }
                                                )}
                                            >
                                                {children}
                                            </div>
                                        )}
                                    >
                                        {primaryTooltipComponent &&
                                        value !== 'B' ? (
                                            <TooltipButton
                                                id={getCellId(row, cell)}
                                                title={getCellTitle(row, cell)}
                                                hideArrow
                                                noPadding
                                                externalTooltip
                                                showOnHover={false}
                                                direction="below"
                                                primaryTooltipContent={primaryTooltipComponent(
                                                    row,
                                                    cell,
                                                    benchmark
                                                )}
                                                classNames={{
                                                    component:
                                                        'stats-table__cell-button-wrapper',
                                                    button: 'stats-table__cell-button'
                                                }}
                                            >
                                                {value}
                                            </TooltipButton>
                                        ) : (
                                            <>{value}</>
                                        )}
                                    </ConditionalWrapper>
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </tbody>
    );
};

StatsTableBody.propTypes = {
    rows: PropTypes.array.isRequired,
    getTableBodyProps: PropTypes.func.isRequired,
    prepareRow: PropTypes.func.isRequired,
    getBenchmark: PropTypes.func,
    primaryTooltipComponent: PropTypes.func,
    getCellId: PropTypes.func,
    getCellTitle: PropTypes.func,
    customCells: PropTypes.object,
    skipCells: PropTypes.array,
    valueWrapper: PropTypes.bool,
    isProspect: PropTypes.bool,
    getTrProps: PropTypes.func
};

const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;
