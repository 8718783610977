import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { MapLocationSelectorOption } from './MapLocationSelectorOption';
import { MapLocationSelectorImage } from './MapLocationSelectorImage';

export const MapLocationSelector = ({ setVenue }) => {
    const mapContainer = document.querySelector('.interactive-event-map');
    const [startingVenue, setStartingVenue] = useState(null);

    const [selectedVenue, setSelectedVenue] = useState({
        name: PULSE.core.localStorage.getStorage('mapSelectedVenue'),
        imgRef: PULSE.core.localStorage.getStorage('mapSelectedVenueLogo'),
        id: PULSE.core.localStorage.getStorage('mapSelectedVenueId')
    });
    const [additionalOptions, setAdditionalOptions] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const urlSearchParams = new URLSearchParams(window.location.search);

    const [logosArray, setLogosArray] = useState([]);

    const locationParams = {
        venue: Number(urlSearchParams.get('location')),
        marker: Number(urlSearchParams.get('id'))
    };

    const handleImageUrl = (locationObject) => {
        setLogosArray((prevLogo) => [...prevLogo, locationObject]);
    };

    const openDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const updateSelectedVenue = (item) => {
        setSelectedVenue(item);
        typeof setVenue === 'function' ? setVenue(item) : null;
        PULSE.app.common.MapLocationManager.updateMapSelectedVenue(item);
        setDropdownOpen(false);
    };

    useEffect(() => {
        const selectionElement = document.querySelector(
            '.js-map-location-selector__button'
        );

        selectionElement.addEventListener('click', openDropdown);

        return () => {
            selectionElement.removeEventListener('click', openDropdown);
        };
    }, [dropdownOpen]);

    useEffect(() => {
        const optionItems = document.querySelectorAll('.js-option-btn');

        for (let i = 0; i < optionItems.length; i++) {
            optionItems[i].addEventListener('click', () => {
                updateSelectedVenue({
                    name: optionItems[i].querySelector('.js-option-btn__name')
                        .innerHTML,
                    imgRef: optionItems[i].querySelector('img')?.src,
                    id: optionItems[i].dataset.venueId
                });
            });
        }

        return () => {
            for (let i = 0; i < optionItems.length; i++) {
                optionItems[i].removeEventListener(
                    'click',
                    updateSelectedVenue
                );
            }
        };
    }, [additionalOptions]);

    useEffect(() => {
        let additionalLocationsArray = [];

        setStartingVenue({
            name: mapContainer.dataset.startingVenue,
            imgRef: {
                contentType: 'PHOTO',
                reference: mapContainer.dataset.startingImage
            },
            id: mapContainer.dataset.startingVenueId
        });

        PULSE.additionalLocations.forEach((additionalVenue) => {
            let imageReference = null;

            additionalVenue.references.forEach((reference) => {
                if (reference.contentReference.contentType === 'PHOTO') {
                    imageReference = reference.contentReference;
                }
            });
            additionalLocationsArray.push({
                name: additionalVenue.title,
                imgRef: imageReference,
                id: additionalVenue.id.toString()
            });

            setAdditionalOptions(additionalLocationsArray);
        });
    }, []);

    useEffect(() => {
        window.addEventListener('mapLocationChange', () => {
            setSelectedVenue({
                name: PULSE.core.localStorage.getStorage('mapSelectedVenue'),
                imgRef: PULSE.core.localStorage.getStorage(
                    'mapSelectedVenueLogo'
                ),
                id: PULSE.core.localStorage.getStorage('mapSelectedVenueId')
            });

            typeof setVenue === 'function'
                ? setVenue({
                      name: PULSE.core.localStorage.getStorage(
                          'mapSelectedVenue'
                      ),
                      imgRef: PULSE.core.localStorage.getStorage(
                          'mapSelectedVenueLogo'
                      ),
                      id: PULSE.core.localStorage.getStorage(
                          'mapSelectedVenueId'
                      )
                  })
                : null;
        });

        if (locationParams.venue > 0) {
            let imgURL;

            logosArray.forEach((item) => {
                if (locationParams.venue === Number(item.id)) {
                    imgURL = item.imgRef;

                    if (
                        item.name ===
                        PULSE.core.localStorage.getStorage('mapSelectedVenue')
                    ) {
                        setSelectedVenue({
                            name: PULSE.core.localStorage.getStorage(
                                'mapSelectedVenue'
                            ),
                            imgRef: imgURL,
                            id: PULSE.core.localStorage.getStorage(
                                'mapSelectedVenueId'
                            )
                        });

                        setVenue({
                            name: PULSE.core.localStorage.getStorage(
                                'mapSelectedVenue'
                            ),
                            imgRef: imgURL,
                            id: PULSE.core.localStorage.getStorage(
                                'mapSelectedVenueId'
                            )
                        });
                    }
                }
            });
        }
    }, [logosArray]);

    return (
        <div
            className={`map-location-selector ${
                dropdownOpen ? 'map-location-selector--active' : ''
            } js-map-location-selector`}
        >
            <button className="map-location-selector__button js-map-location-selector__button">
                {selectedVenue.imgRef !== 'undefined' ||
                startingVenue?.imgRef?.reference.length ? (
                    <MapLocationSelectorImage selectedVenue={selectedVenue} />
                ) : null}
                <span className="map-location-selector__selected-location">
                    {selectedVenue.name}
                </span>
                <span className="map-location-selector__arrow-container">
                    <SvgIcon icon="chevron-blue" className="icon" />
                </span>
            </button>
            <ul
                className={`map-location-selector__options-list ${
                    dropdownOpen ? '' : 'u-hide'
                }`}
            >
                {startingVenue && (
                    <MapLocationSelectorOption
                        selectedVenue={selectedVenue}
                        location={startingVenue}
                        handleImageUrl={handleImageUrl}
                    />
                )}
                {additionalOptions.map((location) => (
                    <MapLocationSelectorOption
                        key={location.name}
                        selectedVenue={selectedVenue}
                        location={location}
                        handleImageUrl={handleImageUrl}
                    />
                ))}
            </ul>
        </div>
    );
};

MapLocationSelector.propTypes = {
    setVenue: PropTypes.func
};
