import { getTranslation } from 'common/react/utils/translations';

/**
 * @param {object} item - Draft pick data.
 * @param {object} currentPick - Current draft number.
 * @param isLive
 * @param year
 * @returns {object[]} Row data for the given pick.
 */
export const composeRowData = (item, currentPick, isLive, year) => {
    if (!isLive) {
        let fullName = '';

        if (item.firstName && item.surname) {
            fullName = item.firstName + ' ' + item.surname;
        } else if (item.fullName) {
            fullName = item.fullName;
        } else {
            fullName = '-';
        }

        return {
            player: {
                fullName: fullName,
                selecting: false,
                passed: item.passed ?? false,
                fatherSon: item.fatherSon ?? false,
                academy: item.academy ?? false,
                photoUrl: item.photoUrl ?? false,
                profileUrl: PULSE.app.common.url.getProspectProfileURL(
                    item.playerId,
                    year
                )
            },
            height: item.height
                ? item.height +
                  ' ' +
                  getTranslation('label.centimetre.abbreviation')
                : '-',
            weight: item.weight
                ? item.weight +
                  ' ' +
                  getTranslation('label.kilogram.abbreviation')
                : '-',
            dob:
                (item.dob?.split('/')[0].length === 1
                    ? '0' + item.dob
                    : item.dob) ?? '-',
            from: item.juniorClub ?? '-'
        };
    }

    return {
        pick: item.pickNumber ?? '',
        club: PULSE.app.common.team.getTeamAbbr(item.teamId) ?? '',
        player: {
            fullName: item.fullName ?? '-',
            selecting:
                item.pickNumber === currentPick
                    ? getTranslation('label.draftTracker.selecting')
                    : false,
            passed: item.passed ?? false,
            fatherSon: item.fatherSon ?? false,
            academy: item.academy ?? false,
            photoUrl: item.photoUrl ?? false,
            profileUrl: PULSE.app.common.url.getProspectProfileURL(
                item.playerId,
                year
            )
        },
        height: item.height
            ? item.height +
              ' ' +
              getTranslation('label.centimetre.abbreviation')
            : '-',
        weight: item.weight
            ? item.weight + ' ' + getTranslation('label.kilogram.abbreviation')
            : '-',
        dob:
            (item.dob?.split('/')[0].length === 1
                ? '0' + item.dob
                : item.dob) ?? '-',
        from: item.juniorClub ?? '-'
    };
};
