import { createApi } from '@reduxjs/toolkit/query/react';

import { accountHeaderWithRetryBQ } from './base-queries/account-header-with-retry';

const { app } = PULSE;

// RTK Query update: Define an api service with a base URL and retry attempts
// RTK Query update: Endpoints can be added here or injected in components
export const aflApi = createApi({
    reducerPath: 'aflAPI',
    baseQuery: accountHeaderWithRetryBQ(app.environment.api),
    endpoints: (build) => ({
        getCompetitions: build.query({
            query: () => 'competitions?pageSize=50',
            transformResponse: (response) => response?.competitions
        }),
        getCompSeasons: build.query({
            query: ({ competitionId, params = { pageSize: 20 } }) =>
                `competitions/${competitionId}/compseasons${app.common.prepareParams(
                    params
                )}`,
            transformResponse: (response) =>
                response?.compSeasons ? [...response.compSeasons] : []
        }),
        getCompSeason: build.query({
            query: ({ id }) => `compseasons/${id}`,
            transformResponse: (response) => response?.compSeasons?.[0]
        }),
        getTeams: build.query({
            query: (compSeasonId) =>
                `teams?compSeasonId=${compSeasonId}&pageSize=100`,
            transformResponse: (response) => response?.teams
        }),
        getMatches: build.query({
            query: (params) => `matches${app.common.prepareParams(params)}`,
            transformResponse: (response) => response?.matches
        }),
        getMatch: build.query({
            query: ({ id }) => `matches/${id}`,
            transformResponse: (response) => response?.matches
        }),
        getLadder: build.query({
            query: ({ compSeasonId, params }) =>
                `compseasons/${compSeasonId}/ladders${app.common.prepareParams(
                    params
                )}`
        }),
        getRounds: build.query({
            query: ({ compseasonId, params }) =>
                `compseasons/${compseasonId}/rounds${app.common.prepareParams(
                    params
                )}`,
            transformResponse: (response) => response?.rounds
        }),
        getVenues: build.query({
            query: (compSeasonId) =>
                `venues?compSeasonId=${compSeasonId}&pageSize=100`,
            transformResponse: (response) => response?.venues
        })
    })
});

export const {
    useGetCompetitionsQuery,
    useGetCompSeasonsQuery,
    useGetCompSeasonQuery,
    useGetTeamsQuery,
    useGetMatchesQuery,
    useGetMatchQuery,
    useGetLadderQuery,
    useGetRoundsQuery,
    useGetVenuesQuery
} = aflApi;
