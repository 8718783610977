import React from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { LadderTableFetcher } from './LadderTableFetcher';
import { LiveLadderDataFetcher } from './LiveLadderDataFetcher';
import { useGetCurrentCompFilters } from 'common/react/components/competition-nav/hooks/use-get-current-comp-filters';
import { liveSelector } from 'store/modules/competition-nav/selectors';
import { EmptyState } from 'common/react/components/EmptyState';

const SKELETON_COUNT = 1;

export const LadderTable = () => {
    const { competitionId, compSeason, round, roundLoadError } =
        useGetCurrentCompFilters();
    const live = useSelector(liveSelector);
    const isLive = live?.isActive;

    if (roundLoadError) {
        return (
            <div style={{ padding: '3.2rem 1.6rem' }}>
                <EmptyState cssClass="competition-nav__empty-state" />
            </div>
        );
    }

    if (!compSeason || !round) {
        return (
            <Skeleton
                className="ladder__item-skeleton"
                count={SKELETON_COUNT}
            />
        );
    }

    if (isLive) {
        return (
            <LiveLadderDataFetcher
                competitionId={competitionId}
                compSeason={compSeason}
                round={round}
            />
        );
    }

    return (
        <LadderTableFetcher
            competitionId={competitionId}
            compSeason={compSeason}
            round={round}
        />
    );
};
