import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import { aflApi } from './afl-api';
import { cfsApi } from './cfs-api';
import { contentApi } from './content-api';
import { cfsCommentaryApi } from './cfsCommentary-api';
import { statsProApi } from './statsPro-api';
import { salesforceSitApi } from './salesforce-sit-api';
import { misPreferencesApi } from './mis-preferences-api';

// RTK Query update: Add required exports
if (window.PULSE.app.redux) {
    window.PULSE.app.redux.configureStore = configureStore;
    window.PULSE.app.redux.createSagaMiddleware = createSagaMiddleware;
    window.PULSE.app.redux.createListenerMiddleware = createListenerMiddleware;
    window.PULSE.app.redux.aflApi = aflApi;
    window.PULSE.app.redux.cfsApi = cfsApi;
    window.PULSE.app.redux.contentApi = contentApi;
    window.PULSE.app.redux.cfsCommentaryApi = cfsCommentaryApi;
    window.PULSE.app.redux.statsProApi = statsProApi;
    window.PULSE.app.redux.salesforceSitApi = salesforceSitApi;
    window.PULSE.app.redux.misPreferencesApi = misPreferencesApi;
} else {
    window.PULSE.app.redux = {
        reducers: {},
        sagas: {},
        configureStore: configureStore,
        createSagaMiddleware: createSagaMiddleware,
        createListenerMiddleware: createListenerMiddleware,
        aflApi: aflApi,
        cfsApi: cfsApi,
        contentApi: contentApi,
        cfsCommentaryApi: cfsCommentaryApi,
        statsProApi: statsProApi,
        salesforceSitApi: salesforceSitApi,
        misPreferencesApi: misPreferencesApi
    };
}
