import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useGetCurrentCompFilters } from 'common/react/components/competition-nav/hooks/use-get-current-comp-filters';
import { FixturesNav } from './FixturesNav';
import { FixturesList } from './fixtures-list/FixturesList';
import { FixturesSkeleton } from './fixtures-list/FixturesSkeleton';
import { EmptyState } from 'common/react/components/EmptyState';
import { useGetAllContentQuery } from 'common/store/modules/api/content-api';

export const Fixtures = (data) => {
    const { competitionId, competitionPid, compSeason, round, roundLoadError } =
        useGetCurrentCompFilters();

    const [scrollToRound, setScrollToRound] = useState(null);

    // contents param should be in format ?contents=PROMO:{reference},PROMO:{reference}
    const broadcasterPromoIds = data?.broadcasterPromos
        ?.split(',')
        .map((id) => `PROMO:${id}`);

    const { data: broadcasterPromoData = [] } = useGetAllContentQuery(
        {
            params: {
                contents: broadcasterPromoIds?.join(',')
            }
        },
        { skip: !broadcasterPromoIds }
    );

    const broadcasterPromo = useMemo(() => {
        const found = broadcasterPromoData.find((promo) => {
            let compReference = promo.references?.find(
                (ref) => ref.type === 'AFL_COMPETITION'
            );
            return compReference.id === competitionId;
        });

        return found;
    }, [broadcasterPromoData, competitionId]);

    return (
        <>
            <FixturesNav {...data} onRoundClick={setScrollToRound} />
            <div className="wrapper">
                {roundLoadError ? (
                    <EmptyState cssClass="competition-nav__empty-state" />
                ) : !competitionId ||
                  !compSeason?.id ||
                  (!round?.roundNumber && round?.roundNumber !== 0) ? (
                    <FixturesSkeleton />
                ) : (
                    <FixturesList
                        competitionId={competitionId}
                        competitionPid={competitionPid}
                        compSeason={compSeason}
                        roundNumber={round.roundNumber}
                        broadcasterPromo={broadcasterPromo}
                        scrollToRound={scrollToRound}
                        setScrollToRound={setScrollToRound}
                    />
                )}
            </div>
        </>
    );
};

Fixtures.propTypes = {
    data: PropTypes.object
};
