/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { StatsTableBody } from 'widgets/statspro/js/components/common/stats-table/StatsTableBody';
import { PlayerImage } from './PlayerImage';

/**
 * @param {object} props - Props.
 * @param {object[]} props.rows - Rows to render.
 * @param {Function} props.getTableBodyProps - Get table body props function.
 * @param {Function} props.prepareRow - Prepare row function.
 * @param {boolean} props.isAFLW - if this is on the AFLW site
 * @param props.compPid
 * @returns {JSX.Element} Component.
 */
export const TableBody = ({ rows, getTableBodyProps, prepareRow, compPid }) => {
    const isAFLW = PULSE.app.common.match.getCompAbbr(compPid) === 'AFLW';
    const isMainCompetition =
        PULSE.app.common.match.getCompAbbr(compPid) === 'AFL' ||
        PULSE.app.common.match.getCompAbbr(compPid) === 'AFLW';

    return (
        <StatsTableBody
            rows={rows}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            customCells={{
                jumperNumber: (row, cell, cellProps) => (
                    <th
                        {...cellProps}
                        className={classNames('stats-table__row-header-cell', {
                            'stats-table__cell--sorted': cell.column.isSorted
                        })}
                    >
                        {isMainCompetition ? (
                            <a
                                href={PULSE.app.common.url.getPlayerProfileURL(
                                    cell?.row?.values?.player?.playerId || '',
                                    isAFLW
                                )}
                                className="mc-player-stats-table__jumper-number-container stats-table__cell--link"
                            >
                                <PlayerImage
                                    photoURL={cell.value.photoURL}
                                    photoName={`${cell?.value?.givenName} ${cell?.value?.surname}`}
                                />
                                <span
                                    className={`mc-player-stats-table__jumper-number mc-player-stats-table__jumper-number--${cell.value.teamLocation} ${cell.value.teamAbbr}`}
                                >
                                    {cell.value.jumperNumber}
                                </span>
                            </a>
                        ) : (
                            <div className="mc-player-stats-table__jumper-number-container">
                                <PlayerImage
                                    photoURL={cell.value.photoURL}
                                    photoName={`${cell?.value?.givenName} ${cell?.value?.surname}`}
                                />
                                <span
                                    className={`mc-player-stats-table__jumper-number mc-player-stats-table__jumper-number--${cell.value.teamLocation} ${cell.value.teamAbbr}`}
                                >
                                    {cell.value.jumperNumber}
                                </span>
                            </div>
                        )}
                    </th>
                ),
                player: (row, cell, cellProps) => (
                    <td {...cellProps} className="stats-table__cell">
                        {isMainCompetition ? (
                            <a
                                href={PULSE.app.common.url.getPlayerProfileURL(
                                    cell?.row?.values?.player?.playerId || '',
                                    isAFLW
                                )}
                                className="mc-player-stats-table__player mc-player-stats-table__player--link "
                            >
                                {cell.value.givenName}{' '}
                                <strong>{cell.value.surname}</strong>
                            </a>
                        ) : (
                            <div className="mc-player-stats-table__player">
                                {cell.value.givenName}{' '}
                                <strong>{cell.value.surname}</strong>
                            </div>
                        )}
                    </td>
                ),
                empty: (row, cell, cellProps) => (
                    <td
                        {...cellProps}
                        className="stats-table__cell stats-table__cell--empty js-player-stats-empty-cell"
                    ></td>
                )
            }}
        />
    );
};

TableBody.propTypes = {
    rows: PropTypes.array.isRequired,
    getTableBodyProps: PropTypes.func.isRequired,
    prepareRow: PropTypes.func.isRequired,
    compPid: PropTypes.string
};
