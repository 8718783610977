import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { filtersSelector } from 'store/modules/competition-nav/selectors/filters-selector';
import {
    useGetTeamsQuery,
    useGetMatchesQuery
} from 'store/modules/api/afl-api';
import { filterByCity } from '../../../../../sites/aflm/fixtures/js/utils/filter-by-city';

import { getTranslation } from '../../../utils/translations';
import { SelectMultiple } from '../../select-multiple';
import { TeamIcon } from '../../TeamIcon';

import { UPDATE_FILTER_ITEM } from 'store/modules/competition-nav/actions';

export const TeamFilterItem = ({ addOnResetCallback, currentFilters }) => {
    const dispatch = useDispatch();

    const { competitionId, compSeason } = currentFilters;
    const filters = useSelector(filtersSelector);

    const { data: teamsList } = useGetTeamsQuery(compSeason.id, {
        skip: !compSeason.id ? true : false
    });

    const teams = useMemo(
        () =>
            teamsList
                ? [...teamsList].sort((a, b) => a.name.localeCompare(b.name))
                : null,
        [teamsList]
    );

    const updateFilterItem = (filterItem, value) => {
        dispatch(UPDATE_FILTER_ITEM.request({ filterItem, value }));
    };

    // if city or venue selected
    // disable non-applicable teams

    const { data: matches = [] } = useGetMatchesQuery(
        {
            pageSize: 300,
            competitionId: competitionId,
            compSeasonId: compSeason.id,
            ...(filters.teams.length && { teamId: filters.teams }),
            ...(filters.venues.length && { venueId: filters.venues })
        },
        {
            skip: !competitionId || !compSeason.id
        }
    );

    const filteredMatches = filters.cities.length
        ? filterByCity(matches, filters.cities)
        : null;

    let activeTeams = [];

    if (filters.venues.length || filters.cities.length) {
        if (filteredMatches?.length) {
            filteredMatches.forEach((match) => {
                activeTeams = [
                    ...activeTeams,
                    match.home?.team?.id,
                    match.away?.team?.id
                ];
            });
        } else if (matches.length) {
            matches.forEach((match) => {
                activeTeams = [
                    ...activeTeams,
                    match.home?.team?.id,
                    match.away?.team?.id
                ];
            });
        }
    }

    return (
        <SelectMultiple
            items={teams}
            getInitialSelectedItems={() => {
                return teams?.filter((t) => filters.teams.includes(t.id));
            }}
            label={getTranslation('label.fixtures.filters.team')}
            icon="shield"
            itemToString={(team) => team.name}
            onSelectedItemsChange={(team) => {
                updateFilterItem(
                    'teams',
                    team.map((t) => t.id)
                );
            }}
            addOnResetCallback={addOnResetCallback}
            getItemText={(item) => item.name}
            getItemLabel={(item) => (
                <span
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        lineHeight: 1,
                        width: '100%'
                    }}
                >
                    <span style={{ marginRight: '1rem' }}>{item.name}</span>
                    <TeamIcon
                        className="icon"
                        providerId={item.providerId}
                        style={{ width: '3.6rem', height: '3.6rem' }}
                    />
                </span>
            )}
            getItemDisabled={(item) =>
                activeTeams.length && !activeTeams.includes(item.id)
            }
        />
    );
};

TeamFilterItem.propTypes = {
    addOnResetCallback: PropTypes.func,
    currentFilters: PropTypes.object
};
