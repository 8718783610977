import { STATS_CATEGORY_IDS } from 'widgets/statspro/js/components/common/config/performance-stats';

export const composeSeasonLineData = (seasonArray, nav, type) => {
    const cumulatives = [];

    let composedData = [];

    if (seasonArray[0]?.benchmarkedStats) {
        composedData = seasonArray.map(
            (item, index) => (
                (cumulatives[index] =
                    index > 0
                        ? item.benchmarkedStats[
                              STATS_CATEGORY_IDS[nav.category]
                          ].value + cumulatives[index - 1]
                        : item.benchmarkedStats[
                              STATS_CATEGORY_IDS[nav.category]
                          ].value),
                {
                    name: item.roundNumber ?? '',
                    playerOneValue: cumulatives[index],
                    playerTwoValue: null,
                    subtext:
                        type !== 'comparison'
                            ? `${item.roundName} v ${item.opponent.teamAbbr}`
                            : item.roundName,
                    playerTwoSubtext: null
                }
            )
        );
    }

    return composedData;
};
