import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchWithRetryBQ } from './base-queries/fetch-with-retry';

const { app } = PULSE;

// RTK Query update: Define an api service with a base URL and retry attempts
// RTK Query update: Endpoints can be added here or injected in components
export const contentApi = createApi({
    reducerPath: 'contentApi',
    baseQuery: fetchWithRetryBQ(app.environment.aflApi),
    endpoints: (builder) => ({
        // GET /content/{accountName}/{type}/{lang}/{id} - Get Content by type/lang and ID
        // id is the content reference string. It's returned as id in the returned object.
        getContentItem: builder.query({
            query: ({ type, id, params }) =>
                app.common.url.createContentPath(type, params, app.language, id)
        }),
        // GET /content/{accountName}/{type}/{lang} - Get Content by type/lang
        getContent: builder.query({
            query: ({ type, params }) => {
                return `/content/${app.account}/${type}/${
                    app.language
                }${app.common.prepareParams(params)}`;
            }
        }),
        // GET /content/{accountName}/all/{lang}
        getAllContent: builder.query({
            query: ({ params }) => {
                return `/content/${app.account}/all/${
                    app.language
                }${app.common.prepareParams(params)}`;
            }
        }),
        // GET /broadcasting/match-events - Get Match Events
        getMatchEvents: builder.query({
            query: (params) => {
                return `/broadcasting/match-events${app.common.prepareParams(
                    params
                )}`;
            }
        }),
        // GET /broadcasting/afl/live/type - Get Live Streams
        getLiveStreams: builder.query({
            query: ({ type, params }) => {
                return `/broadcasting/afl/live/${type}${app.common.prepareParams(
                    params
                )}`;
            }
        }),
        getRegions: builder.query({
            query: (pageSize) =>
                `broadcasting/regions?page=0&pageSize=${
                    pageSize ? pageSize : 100
                }`,
            transformResponse: (response) => response?.content
        }),
        getEvents: builder.query({
            query: ({ eventId, params }) =>
                `eventmanagement/afl/events/${eventId}${app.common.prepareParams(
                    params
                )}`,
            transformResponse: (response) => response.contents
        })
    })
});

export const {
    useGetContentItemQuery,
    useGetContentQuery,
    useGetAllContentQuery,
    useGetMatchEventsQuery,
    useGetLiveStreamsQuery,
    useGetRegionsQuery,
    useGetEventsQuery
} = contentApi;
