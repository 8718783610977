import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';

import { sliderSettings } from '../config/slider';
import { BroadcasterItem } from './BroadcasterItem';
import { Empty } from './Empty';
import { LazyImage } from 'common/react/components/LazyImage';
import { SvgIcon } from 'common/react/components/SvgIcon';

export const Broadcasters = ({ fixture, event, location }) => {
    const model = _getModel(event, location);

    if (
        !model.matchEventData.broadcasters.length &&
        !model.matchEventData.audio.length &&
        !model.matchEventData.video.length
    ) {
        return <Empty />;
    }

    const sliderName = `broadcast-guide-${fixture.id}`;

    return (
        <div className="broadcast-guide__broadcaster-list">
            <Swiper {...sliderSettings(sliderName)}>
                {model.matchEventData.broadcasters.length
                    ? model.matchEventData.broadcasters.map((item, index) => (
                          <SwiperSlide key={index}>
                              <div className="broadcast-guide__broadcaster-item broadcast-guide__broadcaster-item--broadcaster">
                                  <span className="broadcast-guide__broadcaster-type">
                                      &nbsp;
                                  </span>
                                  <div className="broadcast-guide__broadcaster-logo placeholder">
                                      {item.logo &&
                                          item.logo.info.original.url && (
                                              <LazyImage
                                                  url={
                                                      item.logo.info.original
                                                          .url
                                                  }
                                                  objectFit={true}
                                              />
                                          )}
                                  </div>
                                  <span className="broadcast-guide__broadcaster-channel-name">
                                      <strong>{item.name}</strong>
                                  </span>
                              </div>
                          </SwiperSlide>
                      ))
                    : ''}
                {model.matchEventData.video.length
                    ? model.matchEventData.video.map((item, index) => (
                          <SwiperSlide key={index}>
                              <BroadcasterItem
                                  item={item}
                                  type="video"
                                  timezone={location.timezone}
                                  index={index}
                              />
                          </SwiperSlide>
                      ))
                    : ''}
                {model.matchEventData.audio.length
                    ? model.matchEventData.audio.map((item, index) => (
                          <SwiperSlide key={index}>
                              <BroadcasterItem
                                  item={item}
                                  type="audio"
                                  timezone={location.timezone}
                                  index={index}
                              />
                          </SwiperSlide>
                      ))
                    : ''}
            </Swiper>
            <div
                className={`swiper-button-prev-unique-${sliderName} slider-controls__button slider-controls__button--prev`}
            >
                <SvgIcon
                    icon="chevron"
                    className="broadcast-schedule__thumbnail-chevron icon"
                />
            </div>
            <div
                className={`swiper-button-next-unique-${sliderName} slider-controls__button slider-controls__button--next`}
            >
                <SvgIcon
                    icon="chevron"
                    className="broadcast-schedule__thumbnail-chevron icon"
                />
            </div>
        </div>
    );
};

/**
 * Process API response ready for presentation in the template layer.
 *
 * @param { object } matchEvent object from the API
 * @param location
 * @returns { object } modelled matchEvent data
 */
const _getModel = function _getModel(matchEvent, location) {
    let matchEventData = {};
    matchEventData = PULSE.app.common.processMatchEvent(
        matchEvent,
        location.id
    );

    matchEventData.video = matchEventData.video.map(_applyExternalLink);
    matchEventData.audio.forEach(_applyExternalLink);

    const model = {
        timezone: location.timezone,
        matchEventData
    };

    return model;
};

const _applyExternalLink = (channel) => {
    let clone = { ...channel };

    const customAttributes = PULSE.app.common.arrayHelper.indexBy(
        clone.customAttributes,
        'name'
    );
    const externalLink = customAttributes.external_link
        ? customAttributes.external_link.value
        : false;

    clone.externalLink = externalLink;
    return clone;
};

Broadcasters.propTypes = {
    fixture: PropTypes.object,
    event: PropTypes.object.isRequired,
    location: PropTypes.object
};
