import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Table } from './components/Table';
import { Loader } from 'common/react/components/Loader';
import {
    careerAndSeasonStatsNavSelector,
    careerAndSeasonStatsDataTypeSelector
} from 'store/modules/statspro/career-and-season-stats/selectors';
import { composeRowData, composeColumnData } from './utils';
import {
    UPDATE_BENCHMARKING,
    UPDATE_DATA_TYPE
} from 'store/modules/statspro/career-and-season-stats/actions';
import { EmptyState } from 'common/react/components/EmptyState';

import {
    useGetPlayerCareerSeasonStatsQuery,
    useGetPlayerSeasonRoundStatsQuery
} from 'store/modules/api/statsPro-api';

export const CareerAndSeasonStatsTable = ({
    isAFLW,
    isAFLM,
    hideClubLogos
}) => {
    const dispatch = useDispatch();
    const nav = useSelector(careerAndSeasonStatsNavSelector);
    const dataType = useSelector(careerAndSeasonStatsDataTypeSelector);
    const playerId = new URLSearchParams(location.search).get('playerId');
    const [rowData, setRowData] = useState([]);
    const seasons = useSelector(
        (state) =>
            state.seasons[`competition_${nav.competition.id}`]?.list ?? []
    );
    const season = seasons.find((item) => item.id === nav.season.id);
    const seasonId = season?.providerId ?? '';

    const {
        data: playerCareerStats,
        isFetching: playerCareerIsFetching,
        isError: playerCareerIsError
    } = useGetPlayerCareerSeasonStatsQuery(
        { isAFLW: isAFLW, playerId: playerId },
        { skip: !playerId }
    );

    const {
        data: playerSeasonStats,
        isFetching: playerSeasonIsFetching,
        isError: playerSeasonIsError
    } = useGetPlayerSeasonRoundStatsQuery([playerId, seasonId], {
        skip: !playerId || !seasonId
    });

    const playerData = useMemo(
        () => ({
            yearly: playerCareerStats
                ? playerCareerStats.yearlySeasonStats
                : [],
            season: playerSeasonStats ? playerSeasonStats : {}
        }),
        [playerCareerStats, playerSeasonStats]
    );

    useEffect(() => {
        if (
            dataType.type === 'benchmarkedTotals' ||
            (nav.season.id !== -1 && dataType.type === 'benchmarkedAverages')
        ) {
            // Reset benchmarking when returning to totals
            dispatch(UPDATE_BENCHMARKING.request({ isActive: false }));
            dispatch(UPDATE_DATA_TYPE.request({ type: 'totals' }));
            return;
        }

        // Career stats
        if (nav.season.id === -1 && playerData.yearly.length) {
            if (dataType.type === 'totals') {
                // Set yearly row data
                setRowData(
                    playerData.yearly.map((item) => [
                        item.seasonId,
                        {
                            club: item.team ?? '',
                            totals: { ...item.seasonTotals }
                        }
                    ])
                );
            } else if (
                dataType.type === 'averages' ||
                dataType.type === 'benchmarkedAverages'
            ) {
                // Set averages row data
                setRowData(
                    playerData.yearly.map((item) => [
                        item.seasonId,
                        {
                            club: item.team ?? '',
                            averages: { ...item.benchmarkedSeasonAverages }
                        }
                    ])
                );
            }
        } else if (nav.season.id !== -1) {
            // Season stats
            if (!playerData.season[seasonId]) {
                setRowData([]);
            } else {
                // Set rounds row data
                setRowData(
                    playerData.season[seasonId].map((item) => [
                        '',
                        {
                            opponent: item.opponent ?? '',
                            totals: { ...item.stats },
                            round: {
                                roundNumber: item.roundNumber ?? '',
                                roundAbbreviation: item.roundAbbreviation ?? ''
                            },
                            result: {
                                outcome: item.result?.includes('W')
                                    ? 'W'
                                    : item.result?.includes('L')
                                    ? 'L'
                                    : 'D',
                                score: item.result?.match(/[0-9]+-[0-9]+/)?.[0]
                            }
                        }
                    ])
                );
            }
        }
    }, [playerData.yearly, playerData.season, nav.season.id, dataType.type]);

    /**
     * Get columns.
     */
    const columns = useMemo(
        () => composeColumnData(nav.season.id, nav.category, isAFLM),
        [nav.category, rowData]
    );

    /**
     * Get rows.
     */
    const data = useMemo(
        () =>
            rowData.map((item) =>
                composeRowData(
                    item,
                    columns.filter(
                        (col) =>
                            ![
                                'year',
                                'round',
                                'club',
                                'opponent',
                                'result'
                            ].includes(col.accessor)
                    ),
                    nav.season.id,
                    dataType.type
                )
            ),
        [columns]
    );

    if (playerCareerIsError || playerSeasonIsError) {
        // Error handling for when there is no data
        return (
            <div className="career-and-season-stats__empty-state">
                <EmptyState
                    titleTranslation="label.stats.error.noStatsAvailable"
                    summaryTranslation="label.stats.error.noStatsAvailable.summary"
                />
            </div>
        );
    } else if (
        !data.length ||
        playerCareerIsFetching ||
        playerSeasonIsFetching
    ) {
        // Loader for waiting for data
        return (
            <div className="career-and-season-stats__loader">
                <Loader />
            </div>
        );
    }

    return (
        <Table
            columns={columns}
            data={data}
            modifier="career-and-season-stats"
            navSeasonId={nav.season.id}
            cssClass={nav.season.id === -1 ? 'career' : 'season'}
            hideClubLogos={hideClubLogos}
        />
    );
};

CareerAndSeasonStatsTable.propTypes = {
    isAFLW: PropTypes.bool,
    isAFLM: PropTypes.bool,
    hideClubLogos: PropTypes.bool.isRequired
};
