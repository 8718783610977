import React from 'react';
import PropTypes from 'prop-types';

import { getTranslation } from 'common/react/utils/translations';
import { SvgIcon } from 'common/react/components/SvgIcon';

const CLASS_BLOCK = 'player-stats-tooltip-link';

/**
 * @param {object} props - Props.
 * @param {object} props.player - Player.
 * @param {object} props.isAFLW - Player.
 * @returns {JSX.Element} Component.
 */
export const PlayerStatsTooltipLink = ({ player, isAFLW }) => {
    const playerProfileURL = PULSE.app.common.url.getPlayerProfileURL(
        player.details.playerId,
        isAFLW
    );
    const compareURL = PULSE.app.common.url.getCompareURL(
        player.details.playerId,
        null,
        isAFLW
    );

    return (
        <>
            <a href={playerProfileURL} className={CLASS_BLOCK}>
                <div className={`${CLASS_BLOCK}__content`}>
                    <SvgIcon
                        className={`${CLASS_BLOCK}__icon`}
                        icon={`person`}
                        folder="elements"
                        subfolder="stats"
                    />
                    <span>{getTranslation('label.stats.profile')}</span>
                </div>
            </a>
            <a href={compareURL} className={CLASS_BLOCK}>
                <div className={`${CLASS_BLOCK}__content`}>
                    <SvgIcon
                        className={`${CLASS_BLOCK}__icon`}
                        icon={`compare`}
                        folder="elements"
                        subfolder="stats"
                    />
                    <span>
                        {getTranslation('label.statspro.comparePlayers.short')}
                    </span>
                </div>
            </a>
        </>
    );
};

PlayerStatsTooltipLink.propTypes = {
    player: PropTypes.object.isRequired,
    isAFLW: PropTypes.bool
};
