export const composeColumnData = (rounds) => {
    let columns = [
        {
            Header: 'Player',
            accessor: 'player'
        },
        ...rounds
            .filter((col) => {
                return col.name.toLowerCase().includes('round');
            })
            .map((col) => {
                return {
                    Header: col.roundNumber,
                    accessor: 'round' + col.roundNumber
                };
            }),
        {
            Header: 'Total',
            accessor: 'totalVotes'
        },
        {
            Header: 'Odds',
            accessor: 'bettingOdds'
        }
    ];

    return columns;
};
