/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy, useFilters } from 'react-table';

import { TableBody } from './TableBody';
import { StatsTable } from 'widgets/statspro/js/components/common/stats-table/StatsTable';
import { StatsTableHead } from 'widgets/statspro/js/components/common/stats-table/StatsTableHead';

export const Table = ({ columns, data, modifier, cssClass, status }) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable(
            {
                columns,
                data
            },
            useFilters,
            useSortBy
        );

    // ===== Render ========================================================= //

    return (
        <StatsTable
            modifier={modifier}
            getTableProps={getTableProps}
            cssClass={cssClass}
            stickyHeader={<StatsTableHead headerGroup={headerGroups[0]} />}
            // eslint-disable-next-line no-magic-numbers
            longCells={[1, 2, 3, 4, 5, 6]}
        >
            <StatsTableHead headerGroup={headerGroups[0]} />
            <TableBody
                rows={rows}
                prepareRow={prepareRow}
                getTableBodyProps={getTableBodyProps}
                status={status}
            />
        </StatsTable>
    );
};

Table.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    modifier: PropTypes.string,
    cssClass: PropTypes.string,
    status: PropTypes.string
};
