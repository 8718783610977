import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Table } from '../../../../../statspro/js/components/career-and-season-stats/components/table/components/Table';
import PropTypes from 'prop-types';

import { Loader } from 'common/react/components/Loader';
import {
    careerAndSeasonStatsNavSelector,
    careerAndSeasonStatsDataTypeSelector
} from 'store/modules/statspro/career-and-season-stats/selectors';
import { composeRowData } from '../../../../../statspro/js/components/career-and-season-stats/components/table/utils';
import { EmptyState } from 'common/react/components/EmptyState';
import { useGetDraftProspectStatsQuery } from 'common/store/modules/api/cfs-api';
import { composeColumnDataProspect } from '../utils';

export const ProspectCareerAndSeasonStatsTable = ({ hideClubLogos }) => {
    const nav = useSelector(careerAndSeasonStatsNavSelector);
    const dataType = useSelector(careerAndSeasonStatsDataTypeSelector);
    const playerId = new URLSearchParams(location.search).get('playerId');
    const urlParts = window.location.pathname.split('/');
    const year = urlParts[urlParts.length - 1];
    const isProspect = true;

    const {
        data: prospectCareerStats,
        isFetching: prospectCareerIsFetching,
        isError: prospectCareerIsError
    } = useGetDraftProspectStatsQuery({ year: year, playerId });

    const playerData = {
        yearly: prospectCareerStats ? prospectCareerStats.yearlySeasonStats : []
    };

    const compData = useMemo(() => {
        const updatedGroupedCompetitions = {};

        playerData.yearly.forEach((item) => {
            const competitionName = item.competitionName;

            if (!updatedGroupedCompetitions[competitionName]) {
                updatedGroupedCompetitions[competitionName] = [];
            }

            updatedGroupedCompetitions[competitionName].push(item);
        });

        return updatedGroupedCompetitions;
    }, [playerData.yearly]);

    const rowData = useMemo(() => {
        if (nav.season.id === -1 && playerData.yearly.length) {
            if (dataType.type === 'totals') {
                // Set yearly row data
                const transformedData = [];
                for (const competition in compData) {
                    if (competition) {
                        transformedData.push(competition);
                        transformedData.push(
                            ...compData[competition].map((item) => [
                                item.seasonId,
                                {
                                    gamesPlayed: item.gamesPlayed,
                                    club: {
                                        teamAbbr: item.teamAbbr,
                                        teamId: item.teamId,
                                        teamName: item.teamName,
                                        teamNickname: item.teamNickname
                                    },
                                    totals: {
                                        totalClearances: item.totals.clearances,
                                        ...item.totals
                                    }
                                }
                            ])
                        );
                    }
                }
                return transformedData;
            } else if (dataType.type === 'averages') {
                // Set averages row data
                const transformedData = [];
                for (const competition in compData) {
                    if (competition) {
                        transformedData.push(competition);
                        transformedData.push(
                            ...compData[competition].map((item) => [
                                item.seasonId,
                                {
                                    gamesPlayed: item.gamesPlayed,
                                    club: {
                                        teamAbbr: item.teamAbbr,
                                        teamId: item.teamId,
                                        teamName: item.teamName,
                                        teamNickname: item.teamNickname
                                    },
                                    averages: {
                                        totalClearances:
                                            item.averages.clearances,
                                        ...item.averages
                                    }
                                }
                            ])
                        );
                    }
                }
                return transformedData;
            }
        }

        return [];
    }, [playerData.yearly, nav.season.id, dataType.type, compData]);

    // Now, you can use 'rowData' in your component as needed

    /**
     * Get columns.
     */
    const columns = useMemo(
        () => composeColumnDataProspect(nav.season.id, nav.category, false),
        [nav.category, rowData]
    );

    /**
     * Get rows.
     */
    const data = useMemo(
        () =>
            rowData.map((item) =>
                composeRowData(
                    item,
                    columns.filter(
                        (col) =>
                            !['year', 'club', 'gamesPlayed'].includes(
                                col.accessor
                            )
                    ),
                    nav.season.id,
                    dataType.type,
                    isProspect
                )
            ),
        [columns]
    );

    if (prospectCareerIsError) {
        // Error handling for when there is no data
        return (
            <div className="career-and-season-stats__empty-state">
                <EmptyState
                    titleTranslation="label.stats.error.noStatsAvailable"
                    summaryTranslation="label.stats.error.noStatsAvailable.summary"
                />
            </div>
        );
    } else if (!data.length || prospectCareerIsFetching) {
        // Loader for waiting for data
        return (
            <div className="career-and-season-stats__loader">
                <Loader />
            </div>
        );
    }

    return (
        <Table
            columns={columns}
            data={data}
            modifier="career-and-season-stats"
            navSeasonId={nav.season.id}
            cssClass={'career'}
            isProspect={isProspect}
            hideClubLogos={hideClubLogos}
        />
    );
};

ProspectCareerAndSeasonStatsTable.propTypes = {
    hideClubLogos: PropTypes.bool.isRequired
};
