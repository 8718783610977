import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    leaderboardNavSelector,
    leaderboardDataSelector,
    leaderboardFilterSelector,
    leaderboardFavouriteSelector
} from 'store/modules/brownlow-tracker/leaderboard/selectors';
import { Table } from './components/Table';
import { Loader } from 'common/react/components/Loader';
import { EmptyState } from 'common/react/components/EmptyState';
import {
    FETCH_LEADERBOARD_DATA,
    UPDATE_FAVOURITE_PLAYER
} from 'store/modules/brownlow-tracker/leaderboard/actions';
import { composeRowData, composeColumnData } from './utils';
import { useInterval } from 'common/react/hooks/use-interval';
import { useGetTeamsQuery } from 'store/modules/api/afl-api';
import { useGetBrownlowBettingOddsQuery } from 'store/modules/api/cfs-api';

import { useBettingVis } from 'common/react/hooks/use-betting-vis';

const PAGE_SIZE = 15;
const PAGINATION_TIMEOUT = 500;
const POLLING_DELAY = 30000;
/**
 * @constant {number} LEADER_ROUND_DELAY How many rounds until we start
 * to higlight the leader
 */
const LEADER_ROUND_DELAY = 3;

export const LeaderboardTable = () => {
    const dispatch = useDispatch();

    const nav = useSelector(leaderboardNavSelector);

    const seasons = useSelector(
        (state) =>
            state.seasons[`competition_${nav.competition.id}`]?.list ?? []
    );
    const rounds = useSelector(
        (state) => state.rounds[`season_${nav.season.id}`]?.list ?? []
    );
    const { data: teamsData } = useGetTeamsQuery(nav.season.id, {
        skip: !nav.season.id ? true : false
    });
    const teams = teamsData || [];

    const season = seasons.find((item) => item.id === nav.season.id);
    const seasonPid = season?.providerId ?? '';

    const team = teams.find((item) => item.id === nav.team.id);
    const teamId = team?.providerId ?? '';

    const leaderboardData = useSelector((state) =>
        leaderboardDataSelector(state, seasonPid)
    );

    const favourites = useSelector(leaderboardFavouriteSelector);
    const leaderboardFilter = useSelector(leaderboardFilterSelector);

    const { data: bettingOddsData } = useGetBrownlowBettingOddsQuery(null, {
        pollingInterval: PULSE.app.common.CONSTANTS.TIME.ONE_MINUTE_IN_MS
    });

    const [noDataAvailable, setNoDataAvailable] = useState(false);
    const [isLoading, setIsLoading] = useState();

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [shownData, setShownData] = useState([]);

    const [sortByFavourites, setSortByFavourites] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const status = leaderboardData?.status
        ? PULSE.app.common.match.getMatchStatus(leaderboardData.status)
        : '';

    const shouldShowBettingOdds =
        useBettingVis() &&
        status !== PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED &&
        bettingOddsData?.displayInApp === true &&
        bettingOddsData?.outcomes?.length > 0;

    // Fetch leaderboard data
    useEffect(() => {
        dispatch(
            FETCH_LEADERBOARD_DATA.request({
                type: 'season',
                seasonPid: seasonPid,
                teamId: teamId ?? ''
            })
        );

        // reset page
        setCurrentPage(1);
    }, [dispatch, seasonPid, teamId]);

    useEffect(() => {
        setIsLoading(true);
    }, [nav]);

    /**
     * On Player Favourite Click
     * Modify favourites on player add/remove
     *
     * @param {*} playerId
     */
    const onPlayerFavouriteClick = (playerId) => {
        dispatch(
            UPDATE_FAVOURITE_PLAYER.request({
                id: null,
                providerId: playerId
            })
        );
    };

    // set row data
    useEffect(() => {
        setNoDataAvailable(false);

        if (!leaderboardData?.leaderboard?.length) {
            setNoDataAvailable(true);
            setFilteredData([]);
            setShownData([]);
            setIsLoading(false);
            return;
        }

        let items = leaderboardData.leaderboard.map((item) => ({
            eligible: item.eligible ?? false,
            player: {
                ...item.player,
                winner: item.winner ?? false
            },
            roundByRoundVotes: item.roundByRoundVotes ?? [],
            team: { ...item.team },
            totalVotes: item.totalVotes ?? '',
            bettingOdds: shouldShowBettingOdds
                ? bettingOddsData?.outcomes?.find(
                      (outcome) => item.player.playerId === outcome.playerId
                  )?.price ?? null
                : null
        }));

        if (favourites.length && sortByFavourites) {
            items = sortFavourites(items, favourites, teamId);
        }

        setData(items);
        setIsLoading(false);
    }, [
        leaderboardData,
        favourites,
        sortByFavourites,
        status,
        teamId,
        bettingOddsData?.outcomes,
        shouldShowBettingOdds
    ]);

    // apply search filter
    useEffect(() => {
        let items = data;

        if (leaderboardFilter?.query && leaderboardFilter?.query !== '') {
            // split query into seperate words
            const queryWords = leaderboardFilter.query
                .split(' ')
                .map((query) => query.toLowerCase());

            items = data.filter((item) =>
                /**
                 * look through the query words and if any have a match
                 * against either the given or surname then include in the
                 * filter
                 */
                queryWords.some(
                    (query) =>
                        item.player?.givenName?.toLowerCase().includes(query) ||
                        item.player?.surname?.toLowerCase().includes(query)
                )
            );
        }

        setFilteredData(items);
    }, [data, leaderboardFilter]);

    // set the shown data based on the filtered results
    useEffect(() => {
        setShownData(filteredData.slice(0, PAGE_SIZE * currentPage));
    }, [filteredData]);

    /**
     * Get columns
     */
    const columns = useMemo(() => composeColumnData(rounds), [rounds]);

    /**
     * Fetch more data
     *
     * When user requests another page of data
     * via infinite scrolling / pagination
     */
    const fetchMoreData = () => {
        setTimeout(() => {
            let items = data.slice(
                shownData.length,
                shownData.length + PAGE_SIZE
            );
            setShownData((prevState) => [...prevState, ...items]);
            setCurrentPage(currentPage + 1);
        }, PAGINATION_TIMEOUT);
    };

    /**
     * Get rows
     */
    const rowData = useMemo(
        () =>
            shownData.map((item) =>
                composeRowData(
                    item,
                    columns.filter(
                        (col) =>
                            !['player', 'totalVotes', 'bettingOdds'].includes(
                                col.accessor
                            )
                    ),
                    rounds
                )
            ),
        [columns, shownData, rounds]
    );

    // ===== Poll when not concluded ========================================== //

    useInterval(() => {
        if (status !== PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED) {
            dispatch(
                FETCH_LEADERBOARD_DATA.request({
                    type: 'season',
                    seasonPid: seasonPid,
                    teamId: teamId ?? ''
                })
            );
        }
    }, POLLING_DELAY);

    // ===== Leader Total Highlighting ====================================== //

    const [leaderTotal, setLeaderTotal] = useState(null);

    useEffect(() => {
        if (!team) {
            if (status === PULSE.app.common.CONSTANTS.MATCH_STATUS.LIVE) {
                // Check if more than 3 rounds
                if (
                    leaderboardData?.leaderboard?.[0]?.roundByRoundVotes
                        ?.length >= LEADER_ROUND_DELAY
                ) {
                    setLeaderTotal(
                        leaderboardData?.leaderboard?.[0]?.totalVotes
                    );
                }
            } else {
                setLeaderTotal(null);
            }
        }
    }, [status, team, leaderboardData]);

    // ===== Render ============================================== //

    if (isLoading) {
        // Loader for waiting for data
        return (
            <div className="career-and-season-stats__loader">
                <Loader />
            </div>
        );
    } else if (noDataAvailable) {
        // Error handling for when there is no data
        return (
            <div className="career-and-season-stats__empty-state">
                <EmptyState
                    titleTranslation="label.brownlow.tracker.error.noDataAvailable"
                    summaryTranslation="label.brownlow.tracker.error.noDataAvailable.summary"
                />
            </div>
        );
    }

    return (
        <Table
            columns={columns}
            data={rowData}
            fullData={filteredData}
            modifier={`brownlow-tracker-leaderboard ${
                shouldShowBettingOdds
                    ? 'stats-table--brownlow-tracker-leaderboard--show-odds'
                    : ''
            }`}
            update={fetchMoreData}
            key={rowData[0]?.player.playerId}
            seasonPid={seasonPid}
            leaderTotal={leaderTotal}
            favourites={favourites}
            sortByFavourites={sortByFavourites}
            setSortByFavourites={setSortByFavourites}
            onPlayerFavouriteClick={onPlayerFavouriteClick}
            filteredResults={leaderboardFilter?.query?.length > 0}
        />
    );
};

LeaderboardTable.propTypes = {
    cssClass: PropTypes.string
};

/**
 * Sort Favourites
 *
 * Sorts the leaderboard data, by adding the favourites to the front
 * of the data array
 *
 * @param {*} array - Input list of players
 * @param {*} favouritesArray - Array of a users favourite players
 * @param {*} teamFilterId - The providerId of any active team filter
 * @returns {Array} An array of players, sorted by the leader then favourites
 */
const sortFavourites = (array, favouritesArray, teamFilterId) => {
    /**
     * If there is a winner and no team filter or if there is a winner and team
     * filter and the winner is a member of that team then remove them so they
     * can be placed at the front of sorted array
     */
    let winner = null;
    if (
        array?.[0].player.winner === true &&
        ((teamFilterId && array?.[0].team.teamId === teamFilterId) ||
            teamFilterId === '')
    ) {
        winner = array.shift();
    }

    // Split favourites and remaining players into their own array
    const favouritePlayers = [];
    const remainingPlayers = array.filter((player) => {
        if (
            favouritesArray.some(
                (favourite) => favourite.providerId === player.player.playerId
            )
        ) {
            favouritePlayers.push(player);
            return false;
        }

        return true;
    });

    /**
     * Construct the full list with winner first (if there is one), then
     * favourites and then the rest
     */
    if (winner) {
        return [winner, ...favouritePlayers, ...remainingPlayers];
    }

    return [...favouritePlayers, ...remainingPlayers];
};
