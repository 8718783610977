import { ReduxState } from '../../../../types';

/**
 * @param {ReduxState} state - The Redux state.
 * @param {string} seasonPid - The season id you want the match by match of
 * @returns {object} The Brownlow Tracker Match By Match state.
 */
export const matchByMatchSelector = (state, seasonPid) =>
    state.brownlowTracker.matchByMatch?.[seasonPid];

/**
 * Look through a seasons matchVotes array and pull out a single match
 *
 * @param {ReduxState} state - The Redux state.
 * @param {*} seasonPid - The provider id of the season the match is in
 * @param {*} matchId - The provider id of the match you want
 * @returns {object|null|undefined} - The match object from the matchVotes array
 */
export const brownlowMatchSelector = (state, seasonPid, matchId) => {
    const matches = state.brownlowTracker.matchByMatch?.[seasonPid]?.matchVotes;

    if (!matches) {
        return null;
    }

    return matches.find((match) => match.matchId === matchId);
};

/**
 * Look through a seasons matchVotes array and return the latest round with
 * votes
 *
 * @param {ReduxState} state - The Redux state.
 * @param {*} seasonPid - The provider id of the season the match is in
 * @returns {number} - The number of the latest round with votes announced
 */
export const latestAnnouncedRound = (state, seasonPid) => {
    const seasonData = state.brownlowTracker.matchByMatch?.[seasonPid];

    // No season data loaded yet, return null
    if (!seasonData) {
        return null;
    }

    const latestMatch = seasonData?.matchVotes?.[0];

    if (latestMatch) {
        return latestMatch.roundNumber;
    }

    // If season data but empty match list, return 1 to default to round 1
    return 1;
};
