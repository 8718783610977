import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';

export const FavouriteButton = ({
    player,
    onPlayerFavouriteClick,
    favourites
}) => {
    const handleClick = (playerId) => {
        onPlayerFavouriteClick(playerId);
    };

    const isFavourited = useMemo(() => {
        return favourites.some((item) => item.providerId === player.playerId);
    }, [favourites, player.playerId]);

    return (
        <button
            className="stats-table__favourite-button"
            data-favourite-id={player.playerId}
            data-favourite-provider-id={player.playerId}
            onClick={() => handleClick(player.playerId)}
        >
            {isFavourited ? (
                <SvgIcon icon="star-filled" className="icon" />
            ) : (
                <SvgIcon icon="star-empty" className="icon" />
            )}

            {getTranslation('label.favourite')}
        </button>
    );
};

FavouriteButton.propTypes = {
    player: PropTypes.object.isRequired,
    onPlayerFavouriteClick: PropTypes.func,
    favourites: PropTypes.array
};
