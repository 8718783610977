import React from 'react';
import PropTypes from 'prop-types';

import { getTranslation } from 'common/react/utils/translations';
import { getGlossaryDefinitionFromColumnId } from 'widgets/statspro/js/components/common/utils';
import { StatsTableHead } from 'widgets/statspro/js/components/common/stats-table/StatsTableHead';

export const TableHead = ({ headerGroup }) => (
    <StatsTableHead
        headerGroup={headerGroup}
        primaryTooltipComponent={(column) => (
            <strong style={{ fontWeight: 500 }}>
                {getTranslation(`label.statsLeaders.${column.id}.name`)}
            </strong>
        )}
        doNotRenderTooltipForRows={['year', 'round']}
        secondaryTooltipComponent={(column) => {
            const glossaryDefinition = getGlossaryDefinitionFromColumnId(
                column.id
            );

            if (glossaryDefinition) {
                return (
                    <div className="stats-leaders-table-header-tooltip-secondary">
                        <div className="stats-leaders-table-header-tooltip-secondary__border" />
                        <p>{glossaryDefinition}</p>
                    </div>
                );
            }

            return null;
        }}
    />
);

TableHead.propTypes = {
    headerGroup: PropTypes.object.isRequired
};
