import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { MobilePlayerCell } from '.';
import { leaderboardNavSelector } from 'store/modules/brownlow-tracker/leaderboard/selectors';
import { SvgIcon } from 'common/react/components/SvgIcon';

const cssClass = 'brownlow-tracker-leaderboard-mobile-table';

export const MobileTableBody = ({
    rows,
    onPlayerFavouriteClick,
    favourites,
    prepareRow,
    skipCells,
    seasonPid,
    leaderTotal
}) => {
    const [activeRows, setActiveRows] = useState([0]);

    const nav = useSelector(leaderboardNavSelector);

    const rounds = useSelector(
        (state) => state.rounds[`season_${nav.season.id}`]?.list ?? []
    );

    const handleClick = (index) => {
        if (activeRows.indexOf(index) !== -1) {
            setActiveRows(activeRows.filter((item) => item !== index));
        } else {
            setActiveRows((prev) => [...prev, index]);
        }
    };

    const getTrProps = (row) => {
        let classes = '';

        if (
            row.original.player.winner ||
            row.original.totalVotes === leaderTotal
        ) {
            classes = `${cssClass}--is-leader`;
        }

        if (!row.original.player.eligible) {
            classes = classes + ` ${cssClass}--is-ineligible`;
        }

        if (classes.length) {
            return {
                className: classes
            };
        }
    };

    const calcCellWidth = () => {
        const numberOfRounds = rounds?.filter((round) => {
            return round.name.toLowerCase().includes('round');
        }).length;

        return {
            width: `${(100 / numberOfRounds).toFixed(2)}%`
        };
    };

    return (
        <>
            {/* Fake Tbody */}
            <div>
                {rows.map((row, index) => {
                    prepareRow(row);

                    const isActive = activeRows.indexOf(index) !== -1;

                    const cells = row.cells.filter((cell) => {
                        if (!skipCells) {
                            return true;
                        }
                        return !skipCells.includes(cell.column.id);
                    });

                    // sort cells into top and bottom rows
                    const topRow = [],
                        bottomRow = [];

                    cells.forEach((cell) => {
                        if (
                            cell.column.id === 'player' ||
                            cell.column.id === 'totalVotes' ||
                            cell.column.id === 'bettingOdds'
                        ) {
                            topRow.push(cell);
                        } else {
                            bottomRow.push(cell);
                        }
                    });

                    return (
                        <div
                            key={index}
                            {...row.getRowProps()}
                            {...getTrProps(row)}
                        >
                            {/* Fake top row */}
                            <div
                                className={`${cssClass}__body-row ${cssClass}__body-row-top`}
                            >
                                {topRow.map((cell) => {
                                    const cellProps = cell.getCellProps();

                                    switch (cell.column.id) {
                                        case 'player':
                                            return (
                                                <MobilePlayerCell
                                                    {...cellProps}
                                                    player={cell.value}
                                                    onPlayerFavouriteClick={
                                                        onPlayerFavouriteClick
                                                    }
                                                    favourites={favourites}
                                                    cell={cell}
                                                    seasonPid={seasonPid}
                                                />
                                            );
                                        case 'totalVotes':
                                            return (
                                                <div
                                                    {...cellProps}
                                                    className="stats-table__total-votes-cell"
                                                    onClick={() =>
                                                        handleClick(index)
                                                    }
                                                >
                                                    {cell.value}
                                                    <SvgIcon
                                                        className={`icon ${
                                                            isActive
                                                                ? 'icon--flipped'
                                                                : ''
                                                        }`}
                                                        icon="chevron-down"
                                                    />
                                                </div>
                                            );
                                        case 'bettingOdds':
                                            return (
                                                <div
                                                    {...cellProps}
                                                    className="stats-table__betting-odds-cell"
                                                >
                                                    <span>
                                                        {new Intl.NumberFormat(
                                                            'en-AU',
                                                            {
                                                                style: 'currency',
                                                                currency: 'AUD'
                                                            }
                                                        ).format(cell.value)}
                                                    </span>
                                                </div>
                                            );
                                        default:
                                            return <></>;
                                    }
                                })}
                            </div>
                            {/* Fake bottom row */}
                            <div
                                className={`${cssClass}__body-row ${cssClass}__body-row-bottom ${
                                    isActive
                                        ? cssClass + '__body-row-bottom--active'
                                        : ''
                                }`}
                            >
                                {bottomRow.map((cell) => {
                                    const cellProps = cell.getCellProps();
                                    const value = cell.value ? cell.value : '';

                                    return (
                                        /* Fake cell */
                                        <div
                                            {...cellProps}
                                            key={cell.column.id}
                                            style={calcCellWidth()}
                                            className={classNames(
                                                !value
                                                    ? `${cssClass}__body-empty-cell`
                                                    : `${cssClass}__body-cell`,
                                                value === 'B'
                                                    ? `${cssClass}__body-cell--bye`
                                                    : ''
                                            )}
                                        >
                                            {value}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

MobileTableBody.propTypes = {
    rows: PropTypes.array.isRequired,
    onPlayerFavouriteClick: PropTypes.func,
    favourites: PropTypes.array,
    prepareRow: PropTypes.func.isRequired,
    skipCells: PropTypes.array,
    seasonPid: PropTypes.string,
    leaderTotal: PropTypes.number
};
