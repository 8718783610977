import React from 'react';
import PropTypes from 'prop-types';

export const ModalOver18 = ({ title, subtitle, buttons, note }) => {
    return (
        <div className={`modal-age-setting`}>
            {title ? <p className="modal-age-setting__title">{title}</p> : null}
            {subtitle ? (
                <p className="modal-age-setting__subtitle">{subtitle}</p>
            ) : null}

            {buttons ? (
                <div className="modal-age-setting__btn-container">
                    {buttons.map((button) => (
                        <button
                            onClick={button.onClick}
                            className="modal-age-setting__button button-new button-new--primary"
                            key={button.label}
                        >
                            {button.label}
                        </button>
                    ))}
                </div>
            ) : null}
            {note ? <p className="modal-age-setting__note">{note}</p> : null}
        </div>
    );
};

ModalOver18.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            onClick: PropTypes.func.isRequired
        })
    ),
    note: PropTypes.string
};
