/**
 * @param {object} player - Player details.
 * @param {object[]} columns - Column data.
 * @param {'totals'|'averages'|'benchmarkedTotals'|'benchmarkedAverages'} dataType - Data type.
 * @returns {object[]} Row data for the given player.
 */
export const composeRowDataForPlayer = (player, columns, dataType) => {
    const getValue = ({ accessor }) => {
        if (!player.stats[dataType]) {
            return;
        }

        const val = player.stats[dataType][accessor];

        // We need to pick the value off the object for benchmarking data but
        // not for standard. The 0 default is so data that is undefined is still sorted correctly
        // amongst the numerical values.
        return val?.value ?? val ?? 0;
    };

    return {
        rank: -1,
        player: player.details,
        position: player.details.position,
        team: player.team.teamId,
        ...columns.reduce(
            (prev, curr) => ({
                ...prev,
                [curr.accessor]: getValue(curr)
            }),
            {}
        )
    };
};
