import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { leaderboardBettingToggleSelector } from 'store/modules/brownlow-tracker/leaderboard/selectors';
import { leaderboardDataSelector } from 'store/modules/brownlow-tracker/leaderboard/selectors';
import { useGetBrownlowBettingOddsQuery } from 'store/modules/api/cfs-api';

export const MobileTable = ({ seasonPid, children, nextPageButton }) => {
    const bettingToggle = useSelector(leaderboardBettingToggleSelector);
    const leaderboardData = useSelector((state) =>
        leaderboardDataSelector(state, seasonPid)
    );
    const { data: bettingOddsData } = useGetBrownlowBettingOddsQuery(null, {
        pollingInterval: PULSE.app.common.CONSTANTS.TIME.ONE_MINUTE_IN_MS
    });

    const status = leaderboardData?.status
        ? PULSE.app.common.match.getMatchStatus(leaderboardData.status)
        : '';

    const shouldShowBettingOdds =
        status !== PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED &&
        bettingOddsData?.displayInApp === true &&
        bettingOddsData?.outcomes?.length;

    const modifier =
        bettingToggle.isActive && shouldShowBettingOdds
            ? 'brownlow-tracker-leaderboard-mobile-table--show-odds'
            : '';

    return (
        <>
            <div
                className={`brownlow-tracker-leaderboard-mobile-table ${modifier}`}
            >
                {children}
            </div>
            {nextPageButton}
        </>
    );
};

MobileTable.propTypes = {
    seasonPid: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
    nextPageButton: PropTypes.any,
    modifier: PropTypes.string
};
