import React, { useEffect, useState } from 'react';
import { SvgIcon } from 'common/react/components/SvgIcon';
import PropTypes from 'prop-types';

import { getOpeningTimes, isLocationOpen } from '../util';
import { lookupTranslation } from 'common/react/utils/translations';

export const InformationCardOpening = ({
    selectedLocation,
    googlePlacesData,
    timezone
}) => {
    const [showWeekdays, setShowWeekdays] = useState(false);
    const isOpen = isLocationOpen(selectedLocation, googlePlacesData, timezone);
    const usesCustomTimes = () =>
        selectedLocation?.metadata?.map_event_opening_hour ||
        selectedLocation?.metadata?.map_event_closing_hour
            ? true
            : false;

    const shouldShowHours =
        (selectedLocation?.metadata?.map_event_opening_hour &&
            selectedLocation?.metadata?.map_event_closing_hour) ||
        googlePlacesData !== null;

    useEffect(() => {
        const dropdownBtn = document.querySelector(
            '.interactive-event-map__location-weekday-hours-toggle'
        );

        const updateShowWeekDays = () => {
            setShowWeekdays(!showWeekdays);
        };

        dropdownBtn.addEventListener('click', updateShowWeekDays);

        return () => {
            dropdownBtn.removeEventListener('click', updateShowWeekDays);
        };
    }, [showWeekdays]);

    return (
        <section className="interactive-event-map__location-data interactive-event-map__section">
            <div className="interactive-event-map__location-address">
                <SvgIcon icon="location" className="icon" />
                <p>
                    {selectedLocation?.metadata?.map_event_address
                        ? selectedLocation?.metadata?.map_event_address
                        : googlePlacesData?.formatted_address}
                </p>
            </div>

            <div
                className={`interactive-event-map__location-opening-info ${
                    shouldShowHours ? '' : 'u-hide'
                }`}
            >
                <div className="interactive-event-map__location-hours-info">
                    <SvgIcon icon="clock" className="icon" />
                    <p className="interactive-event-map__location-hours">
                        <span
                            className={`interactive-event-map__location-status ${
                                isOpen
                                    ? ''
                                    : 'interactive-event-map__location-status--closed'
                            }`}
                        >
                            {isOpen ? 'Open' : 'Closed'}{' '}
                        </span>
                        {lookupTranslation('label.location.openCloseString', {
                            status: isOpen ? 'Closes' : 'Opens',
                            time: getOpeningTimes(
                                googlePlacesData,
                                selectedLocation,
                                timezone
                            )
                        })}
                    </p>
                    <button
                        className={`interactive-event-map__location-weekday-hours-toggle ${
                            usesCustomTimes() ? 'u-hide' : ''
                        }`}
                    >
                        <SvgIcon
                            icon="chevron-down"
                            className={`icon ${
                                showWeekdays ? 'toggle-reverse' : ''
                            }`}
                        />
                    </button>
                </div>
                {showWeekdays === true ? (
                    <div className="interactive-event-map__location-weekday-hours">
                        {googlePlacesData?.opening_hours?.weekday_text.map(
                            (day) => (
                                <p
                                    className="interactive-event-map__location-weekday"
                                    key={day}
                                >
                                    {day}
                                </p>
                            )
                        )}
                    </div>
                ) : null}
            </div>
        </section>
    );
};

InformationCardOpening.propTypes = {
    selectedLocation: PropTypes.shape({
        metadata: PropTypes.shape({
            map_event_address: PropTypes.string, // eslint-disable-line camelcase
            map_event_opening_hour: PropTypes.number, // eslint-disable-line camelcase
            map_event_closing_hour: PropTypes.number // eslint-disable-line camelcase
        })
    }),
    googlePlacesData: PropTypes.shape({
        formatted_address: PropTypes.string, // eslint-disable-line camelcase
        // eslint-disable-next-line camelcase
        opening_hours: PropTypes.shape({
            weekday_text: PropTypes.arrayOf(PropTypes.string) // eslint-disable-line camelcase
        })
    }),
    timezone: PropTypes.string
};
