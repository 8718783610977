import React from 'react';
import PropTypes from 'prop-types';

import { PlayerStatsTooltipHeader } from './PlayerStatsTooltipHeader';
import { PlayerStatsTooltipMainStat } from './PlayerStatsTooltipMainStat';
import { PlayerStatsTooltipRelatedStats } from './PlayerStatsTooltipRelatedStats';
import { PlayerStatsTooltipLink } from './PlayerStatsTooltipLink';

/**
 * @param {object} props - Props.
 * @param {object} props.row - Row.
 * @param {object} props.cell - Cell.
 * @param {object} [props.benchmark] - Benchmark.
 * @returns {JSX.Element} Component.
 */
export const PlayerStatsTooltipContent = (props) => {
    const player = props.players.find(
        (_player) => _player.details.playerId === props.row.player.playerId
    );

    return (
        <div>
            <PlayerStatsTooltipHeader player={player} />
            <PlayerStatsTooltipMainStat {...props} />
            <PlayerStatsTooltipRelatedStats {...props} player={player} />
            <PlayerStatsTooltipLink player={player} isAFLW={props.isAFLW} />
        </div>
    );
};

PlayerStatsTooltipContent.propTypes = {
    row: PropTypes.object.isRequired,
    cell: PropTypes.object.isRequired,
    players: PropTypes.array.isRequired,
    benchmark: PropTypes.string,
    isAFLW: PropTypes.bool
};
