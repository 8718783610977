import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import { useCareerAndSeasonStatsNavSync } from '../../../../statspro/js/components/career-and-season-stats/hooks/use-career-and-season-stats-nav-sync';
import { ProspectCareerAndSeasonStatsTable } from '../career-and-season-stats/table/ProspectCareerAndSeasonStatsTable';
import { useCompetitionNavigationOptions } from 'common/react/hooks/use-competition-navigation-options';
import { careerAndSeasonStatsNavSelector } from 'store/modules/statspro/career-and-season-stats/selectors';

import {
    DataTypeToggle,
    Heading
} from '../../../../statspro/js/components/career-and-season-stats/components/header';

export const ProspectCareerAndSeasonStats = ({ title, hideClubLogos }) => {
    const nav = useSelector(careerAndSeasonStatsNavSelector);
    const options = useCompetitionNavigationOptions(nav);
    const isProspect = true;
    useCareerAndSeasonStatsNavSync(options);

    return (
        <StatsSection.Main cssClass={'draft-prospect-stats'}>
            <StatsSection.Heading title={title} />
            <StatsSection.Content>
                <StatsSection.ContentHeader>
                    <StatsSection.ContentHeaderHeading>
                        <Heading isProspect={isProspect} />
                    </StatsSection.ContentHeaderHeading>
                    {nav.season.id === -1 && (
                        <StatsSection.ContentHeaderOptions>
                            <DataTypeToggle />
                        </StatsSection.ContentHeaderOptions>
                    )}
                </StatsSection.ContentHeader>
                <StatsSection.ContentBody>
                    <ProspectCareerAndSeasonStatsTable
                        hideClubLogos={hideClubLogos === 'true' ? true : false}
                    />
                </StatsSection.ContentBody>
            </StatsSection.Content>
        </StatsSection.Main>
    );
};

ProspectCareerAndSeasonStats.propTypes = {
    title: PropTypes.string.isRequired,
    hideClubLogos: PropTypes.string
};
