import React from 'react';
import PropTypes from 'prop-types';

import { TeamIcon } from 'common/react/components/TeamIcon';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { TeamWatermarkBackground } from '../../../statspro/js/components/TeamWatermarkBackground';
import { getTranslation } from 'common/react/utils/translations';

export const MatchCardHeader = ({ voteData, matchData, modifier }) => {
    return (
        <div className="brownlow-match-card__header">
            <TeamWatermarkBackground
                teamAbbreviation={matchData.home.abbr.toLowerCase()}
                className="stats-card-featured-player__bg stats-card-featured-player__bg--no-border-radius"
                side="left"
            />
            <TeamWatermarkBackground
                teamAbbreviation={matchData.away.abbr.toLowerCase()}
                className="stats-card-featured-player__bg stats-card-featured-player__bg--no-border-radius"
                side="right"
            />

            <div
                className={`brownlow-match-card__label brownlow-match-card__label--${
                    modifier ?? ''
                }`}
            >
                {modifier !== 'round-by-round' ? (
                    <>
                        <span className="brownlow-match-card__round-label">
                            {getTranslation(
                                'label.brownlow.tracker.round.abbrevation'
                            )}
                            {voteData.roundNumber}
                        </span>
                        {matchData.home.abbr}
                        <span className="brownlow-match-card__round-label-versus">
                            {getTranslation('label.brownlow.tracker.versus')}
                        </span>
                        {matchData.away.abbr}
                    </>
                ) : (
                    <>
                        {matchData.home.name}
                        <span className="brownlow-match-card__round-label-versus">
                            {getTranslation('label.brownlow.tracker.versus')}
                        </span>
                        {matchData.away.name}
                    </>
                )}
            </div>

            <TeamIcon
                className="icon brownlow-match-card__header-team-icon"
                teamAbbreviation={matchData.home.abbr}
                theme="light"
            />
            <div className="brownlow-match-card__score-container">
                <span className="brownlow-match-card__score">
                    {matchData.home.totalScore}
                </span>
                <SvgIcon
                    icon="versus-white"
                    className="icon brownlow-match-card__versus"
                    folder="elements"
                    subfolder="stats"
                />
                <span className="brownlow-match-card__score brownlow-match-card__score--away">
                    {matchData.away.totalScore}
                </span>
            </div>
            <TeamIcon
                teamAbbreviation={matchData.away.abbr}
                className="icon brownlow-match-card__header-team-icon"
                theme="light"
            />
        </div>
    );
};

MatchCardHeader.propTypes = {
    voteData: PropTypes.object,
    matchData: PropTypes.object.isRequired,
    modifier: PropTypes.string
};
