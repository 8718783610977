import React from 'react';

import { getTranslation } from '../utils/translations';
import { SvgIcon } from './SvgIcon';

export const SettingsButton = () => {
    return (
        <button
            className="filter-button"
            onClick={() => PULSE.app.common.siteSettings.openSettingsModal()}
        >
            <SvgIcon icon="settings" className="filter-button__icon" />

            <span className="u-hide-until-tablet">
                {getTranslation('label.settings')}
            </span>
        </button>
    );
};
