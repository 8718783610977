import { StatsSection as Main } from './StatsSection';
import { StatsSectionHeading } from './StatsSectionHeading';
import {
    StatsSectionContent,
    StatsSectionContentBody,
    StatsSectionContentHeader,
    StatsSectionContentError,
    StatsSectionContentHeaderHeading,
    StatsSectionContentHeaderOptions,
    StatsSectionContentHeaderBelow
} from './StatsSectionContent';
import {
    StatsSectionNav,
    StatsSectionNavItems,
    StatsSectionNavFilterItems,
    StatsSectionRoundButtons,
    StatsSectionSettingsButton
} from './StatsSectionNav';
import { StatsSectionAdditionalNavItems } from './StatsSectionNav/StatsSectionModalButtons';

export const StatsSection = {
    Main: Main,
    Heading: StatsSectionHeading,
    Content: StatsSectionContent,
    ContentHeader: StatsSectionContentHeader,
    ContentHeaderHeading: StatsSectionContentHeaderHeading,
    ContentHeaderOptions: StatsSectionContentHeaderOptions,
    ContentHeaderBelow: StatsSectionContentHeaderBelow,
    ContentBody: StatsSectionContentBody,
    ContentError: StatsSectionContentError,
    Nav: StatsSectionNav,
    NavItems: StatsSectionNavItems,
    NavFilterItems: StatsSectionNavFilterItems,
    RoundButtons: StatsSectionRoundButtons,
    AdditionalNavItems: StatsSectionAdditionalNavItems,
    SettingsButton: StatsSectionSettingsButton
};
