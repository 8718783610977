import { getValueWithinBounds } from '../../../utils/get-value-within-bounds';

/* eslint-disable no-magic-numbers */
const TOOLTIP_WIDTH = 300; // 30rem wide as defined in CSS.
const TOOLTIP_CENTRE = TOOLTIP_WIDTH / 2;
const ARROW_OFFSET = 6;
const PADDING = 0;

export const positionExternalTooltipElements = ({
    target,
    wrapper,
    primary,
    arrow,
    direction
}) => {
    if (!wrapper) {
        return;
    }
    positionHorizontally({ target, wrapper, primary, arrow });
    positionVertically({ target, wrapper, direction });
};

const positionHorizontally = ({ target, wrapper, primary, arrow }) => {
    const triggerRect = target.getBoundingClientRect();

    /**
     * Handle the horizontal positioning of the wrapper.
     *
     * 1. Calculate the left position of the tooltip wrapper to make sure the
     *    tooltip is centred to the target.
     * 2. Calculate the maximum and minimum values allowed to ensure the tooltip
     *    falls fully within the viewport, as we don't want to render any of it
     *    out of view of the user.
     * 3. Use the original left position unless it falls out of bounds of the
     *    maximum or minimum values, in which case use the appropriate
     *    alternative value.
     * 4. Apply the left position to the wrapper.
     *    Note: The horizontal centering is done via a CSS `transform`.
     * 5. Return the difference between the original and the actual value used.
     */
    const difference = (() => {
        // [1]
        const originalLeft = triggerRect.left + triggerRect.width / 2;

        // [2]
        const minLeft = TOOLTIP_CENTRE + PADDING;
        const maxLeft = window.innerWidth - TOOLTIP_CENTRE - PADDING;

        // [3]
        const actualLeft = getValueWithinBounds(originalLeft, minLeft, maxLeft);

        // [4]
        wrapper.style.left = `${actualLeft}px`;

        // [5]
        return originalLeft - actualLeft;
    })();

    /**
     * Handle the horizontal positioning of the primary tooltip and arrow.
     *
     * 1. Reset the left position of the primary tooltip and arrow to make sure
     *    further calculations start from their natural positions on screen.
     * 2. Calculate the maximum and minimum values allowed to ensure the tooltip
     *    falls fully within the viewport, as we don't want to render any of it
     *    out of view of the user.
     * 3. Use the original left position unless it falls out of bounds of the
     *    maximum or minimum values, in which case use the appropriate
     *    alternative value.
     * 4. Apply the left position to the primary tooltip and arrow.
     *    Note: The horizontal centering is done via a CSS `transform`.
     */
    (() => {
        // [1]
        primary.style.left = 0;
        arrow.style.left = 0;

        const primaryRect = primary.getBoundingClientRect();

        // [2]
        const minLeft = primaryRect.width / 2 - TOOLTIP_CENTRE + PADDING;
        const maxLeft =
            window.innerWidth - primaryRect.width - primaryRect.x - PADDING;

        // [3]
        const diffLeft = getValueWithinBounds(difference, minLeft, maxLeft);

        // [4]
        primary.style.left = `${diffLeft}px`;
        arrow.style.left = `${diffLeft - ARROW_OFFSET}px`;
    })();
};

const positionVertically = ({ target, wrapper, direction }) => {
    const targetRect = target.getBoundingClientRect();

    if (direction === 'below') {
        wrapper.style.top = `${
            targetRect.y + targetRect.height + window.scrollY
        }px`;
    } else {
        const wrapperRect = wrapper.getBoundingClientRect();
        wrapper.style.top = `${
            targetRect.y + window.scrollY - wrapperRect.height
        }px`;
    }
};
