/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';

import { StatsTableFoot } from 'widgets/statspro/js/components/common/stats-table/StatsTableFoot';
import { getTranslation } from 'common/react/utils/translations';
import { useSelector } from 'react-redux';
import { careerAndSeasonStatsDataTypeSelector } from 'store/modules/statspro/career-and-season-stats/selectors';
import classNames from 'classnames';

/**
 * @param {object} props - Props.
 * @param {object[]} props.columns - Columns.
 * @param {object[]} props.rows - Row to render.
 * @param {number} props.navSeasonId - nav season ID.
 * @returns {JSX.Element} Component.
 */
export const TableFoot = ({ columns, rows, navSeasonId }) => {
    const dataType = useSelector(careerAndSeasonStatsDataTypeSelector);

    // Get totals for each column
    const columnsToTotal = columns.filter(
        (col) =>
            !['year', 'round', 'club', 'opponent', 'result'].includes(
                col.accessor
            )
    );

    const sums = {};

    columnsToTotal.forEach((col) => {
        let sum = 0;

        // filtering only rows with the required data
        const rowsWithValues = rows.filter(
            (row) => typeof row.values[col.accessor] !== 'undefined'
        );

        // sum of all rows of the columns
        sum = rowsWithValues.reduce(
            (acc, currentValue) => acc + currentValue.values[col.accessor],
            0
        );

        // for averages display average (sum / number of rows)
        // skipping 'gamesPlayed' as this should be displayed as the normal sum
        if (
            col.accessor !== 'gamesPlayed' &&
            (dataType?.type === 'averages' ||
                dataType?.type === 'benchmarkedAverages') &&
            rowsWithValues.length > 0
        ) {
            sums[col.accessor] = sum / rowsWithValues.length;
        } else {
            sums[col.accessor] = sum;
        }
    });

    return (
        <StatsTableFoot>
            <th
                className={classNames(
                    'stats-table__row-header-cell stats-table__foot-row-header-cell',
                    {
                        'stats-table__foot-row-header-cell--season':
                            navSeasonId !== -1
                    }
                )}
                colSpan={2}
            >
                <span className="u-hide-until-desktop">
                    {navSeasonId === -1
                        ? getTranslation('label.stats.career') + ' '
                        : getTranslation('label.stats.season') + ' '}
                </span>
                {getTranslation('label.stats.totals')}
            </th>
            {navSeasonId !== -1 && (
                <td className="stats-table__cell stats-table__foot-cell stats-table__foot-cell--result"></td>
            )}
            {columnsToTotal.map((col, index) => (
                <td
                    key={index}
                    className="stats-table__cell stats-table__foot-cell"
                >
                    {parseFloat(sums[col.accessor].toFixed(1))}
                </td>
            ))}
        </StatsTableFoot>
    );
};

TableFoot.propTypes = {
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    navSeasonId: PropTypes.number.isRequired
};
