import { STATS_CATEGORY_IDS } from 'widgets/statspro/js/components/common/config/performance-stats';

export const composeSeasonBarData = (seasonArray, nav, type) => {
    let composedData = [];

    if (seasonArray[0]?.benchmarkedStats) {
        composedData = seasonArray.map((item) => ({
            name: item.roundNumber ?? '',
            playerOneValue:
                item.benchmarkedStats[STATS_CATEGORY_IDS[nav.category]]?.value,
            benchmark:
                item.benchmarkedStats[STATS_CATEGORY_IDS[nav.category]]
                    ?.benchmark ?? '',
            subtext:
                type !== 'comparison'
                    ? `${item.roundName} v ${item.opponent.teamAbbr}`
                    : item.roundName
        }));
    }

    return composedData;
};
