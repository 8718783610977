import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { PlayerSearchInput } from 'common/react/components/PlayerSearchInput';

import { UPDATE_LEADERBOARD_FILTER } from 'store/modules/brownlow-tracker/leaderboard/actions';

export const Search = () => {
    const [query, setQuery] = useState('');
    const [debouncedQuery, setDebouncedQuery] = useState(query);

    const dispatch = useDispatch();

    const onSearchSubmit = async (newQuery) => {
        dispatch(
            UPDATE_LEADERBOARD_FILTER.request({
                query: newQuery
            })
        );
    };

    useEffect(() => {
        const timer = setTimeout(() => setQuery(debouncedQuery.trim()), 1000);
        return () => clearTimeout(timer);
    }, [debouncedQuery]);

    useEffect(() => {
        if (query !== '' && query.length > 2) {
            onSearchSubmit(query);
        } else {
            onSearchSubmit('');
        }
    }, [query]);

    return (
        <>
            <PlayerSearchInput setDebouncedQuery={setDebouncedQuery} />
        </>
    );
};
