/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';

import { StatsTableBody } from 'widgets/statspro/js/components/common/stats-table/StatsTableBody';
import { PlayerCell } from './PlayerCell';
import { TooltipContent } from '../../../../Tooltip/TooltipContent';

/**
 * @param {object} props - Props.
 * @param {object[]} props.rows - Rows to render.
 * @param {Function} props.getTableBodyProps - Get table body props function.
 * @param {Function} props.prepareRow - Prepare row function.
 * @param props.seasonPid
 * @param props.onPlayerFavouriteClick
 * @param props.favourites
 * @param props.leaderTotal
 * @returns {JSX.Element} Component.
 */
export const TableBody = ({
    rows,
    getTableBodyProps,
    prepareRow,
    seasonPid,
    onPlayerFavouriteClick,
    favourites,
    leaderTotal
}) => {
    return (
        <StatsTableBody
            rows={rows}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            valueWrapper={true}
            getCellId={(row, cell) => getId(cell, row)}
            getCellTitle={(row, cell) => getTitle(cell, row)}
            getTrProps={(row) => {
                let classes = 'stats-table__body-row';

                if (
                    row.original.player.winner ||
                    row.original.totalVotes === leaderTotal
                ) {
                    classes = classes + ' stats-table__body-row--is-leader';
                }

                if (!row.original.player.eligible) {
                    classes =
                        classes +
                        ' stats-table__body-row stats-table__body-row--is-ineligible';
                }

                return {
                    className: classes
                };
            }}
            primaryTooltipComponent={(row, cell) => (
                <TooltipContent
                    row={row.original}
                    cell={cell}
                    player={row.original.player.playerId}
                    seasonPid={seasonPid}
                />
            )}
            customCells={{
                player: (row, cell, cellProps) => (
                    <PlayerCell
                        {...cellProps}
                        player={cell.value}
                        onPlayerFavouriteClick={onPlayerFavouriteClick}
                        favourites={favourites}
                        cell={cell}
                        seasonPid={seasonPid}
                    />
                ),
                totalVotes: (row, cell, cellProps) => (
                    <td
                        {...cellProps}
                        className="stats-table__total-votes-cell"
                    >
                        {cell.value}
                    </td>
                ),
                bettingOdds: (row, cell, cellProps) => (
                    <td
                        {...cellProps}
                        className="stats-table__betting-odds-cell"
                    >
                        <span>
                            {new Intl.NumberFormat('en-AU', {
                                style: 'currency',
                                currency: 'AUD'
                            }).format(cell.value)}
                        </span>
                    </td>
                )
            }}
        />
    );
};

TableBody.propTypes = {
    rows: PropTypes.array.isRequired,
    getTableBodyProps: PropTypes.func.isRequired,
    prepareRow: PropTypes.func.isRequired,
    seasonPid: PropTypes.string,
    onPlayerFavouriteClick: PropTypes.func,
    favourites: PropTypes.array,
    leaderTotal: PropTypes.number
};

const getId = (cell, row) =>
    `brownlow-leaderboard-${row.original.player.givenName}-${row.original.player.surname}-${cell.column.id}`;

const getTitle = (cell, row) =>
    `${row.original.player.givenName} ${row.original.player.surname}: ${
        cell.value ? cell.value : '0'
    } votes.`;
