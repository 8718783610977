import { useEffect } from 'react';
import { CupertinoPane } from 'cupertino-pane';

export const useCupertinoPane = () => {
    const telstraNavHeight =
        document.querySelector('.js-telstra-bar').offsetHeight;
    const mainNavHeight = document.querySelector(
        '.js-main-navigation'
    ).offsetHeight;
    const cupertinoPaddingTop = 30;
    const isMobile = window.matchMedia(
        `(max-width: ${PULSE.app.measurements.desktop}px)`
    );

    const mobileNavHeight = telstraNavHeight + mainNavHeight;
    let transitionOffset = 200;

    /**
     * Retrieves the Y-axis translation value from the transform property of the '.pane' element.
     *
     * @returns {number} The Y-axis translation value in pixels, or 0 if the '.pane' element is not found.
     */
    function getPaneTransformY() {
        const translateYRegex = /\.*translateY\((.*)px\)/i;
        const paneEl = document.querySelector('.pane');
        return paneEl
            ? parseFloat(translateYRegex.exec(paneEl.style.transform)[1])
            : 0;
    }

    /**
     * Sets the height and overflow property of the specified content element based on the current breakpoint of the drawer.
     *
     * @param {HTMLElement} content - The content element to set the height and overflow property.
     * @param {HTMLElement} drawer - The drawer object representing the current breakpoint.
     */
    function setHeight(content, drawer) {
        if (drawer.currentBreak() === 'top') {
            content.style.height = `${
                window.innerHeight -
                mobileNavHeight -
                content.offsetTop -
                cupertinoPaddingTop
            }px`;
        }
    }

    useEffect(() => {
        let locationCard = document.querySelector(
            '.interactive-event-map__location-container'
        );
        let markersList = document.querySelector(
            '.interactive-event-map__information-list'
        );

        const locationSelectorHeight = document.querySelector(
            '.js-map-location-selector'
        ).offsetHeight;

        const mobileDrawerHeight =
            telstraNavHeight +
            mainNavHeight +
            locationSelectorHeight +
            cupertinoPaddingTop;

        const mapContainerHeight = document.getElementById(
            'interactive-event-map'
        );

        if (isMobile.matches) {
            mapContainerHeight.style.height = `calc(100vh - ${mobileDrawerHeight}px)`;
        }

        let settings = {
            cssClass: 'interactive-event-map__cupertino-pane',
            initialBreak: 'middle',
            parentElement: '.interactive-event-map',
            buttonDestroy: false,
            lowerThanBottom: false,
            topperOverflow: false,
            dragBy: ['.draggable'],
            breaks: {
                top: { enabled: true, height: window.innerHeight },
                middle: { enabled: true, height: mobileDrawerHeight },
                bottom: { enabled: true, height: mobileDrawerHeight - 1 } // -1 becausecupertino-pane needs bottom height to be smaller than middle
            },
            events: {
                onDrag: () => {
                    // The locations list height
                    markersList.computedStyleMap.height = `${
                        window.screen.height -
                        getPaneTransformY() -
                        markersList.offsetTop
                    }px`;

                    // The location's information card height
                    locationCard.computedStyleMap.height = `${
                        window.screen.height -
                        getPaneTransformY() -
                        locationCard.offsetTop
                    }px`;
                },
                onDidPresent: () => {
                    markersList.setAttribute(
                        'style',
                        'overflow-y: auto !important'
                    );
                    locationCard.setAttribute(
                        'style',
                        'overflow-y: auto !important'
                    );
                },
                onTransitionEnd: () => {
                    setTimeout(() => {
                        setHeight(markersList, myPane);
                        setHeight(locationCard, myPane);
                    }, transitionOffset);
                }
            }
        };
        const cupertinoPane = document.querySelector('.cupertino-pane');
        let myPane;

        if (cupertinoPane) {
            myPane = new CupertinoPane('.cupertino-pane', settings);
        }

        const initPane = async () => {
            await myPane?.present({ animate: true });
        };

        initPane();
    }, []);
};
