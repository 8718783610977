/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { StatsLeadersPlayerCell } from './StatsLeadersPlayerCell';
import { StatsLeadersRankCell } from './StatsLeadersRankCell';
import { getRanks } from 'widgets/statspro/js/components/common/utils';
import {
    statsLeadersBenchmarkingSelector,
    statsLeadersDataTypeSelector,
    statsLeadersSortSelector
} from 'store/modules/statspro/stats-leaders/selectors';
import { STATS_COLUMNS } from 'widgets/statspro/js/components/common/config/columns';
import { StatsTableBody } from 'widgets/statspro/js/components/common/stats-table/StatsTableBody';
import { PlayerStatsTooltipContent } from './PlayerStatsTooltipContent';

/**
 * @param {object} props - Props.
 * @param {object[]} props.page - Rows to render.
 * @param {Function} props.getTableBodyProps - Get table body props function.
 * @param {Function} props.prepareRow - Prepare row function.
 * @param {object[]} props.preFilteredRows - All rows before filtering has been applied.
 * @param {object[]} props.players - List of players.
 * @param props.isAFLW
 * @returns {JSX.Element} Component.
 */
export const StatsLeadersTableBody = ({
    page,
    getTableBodyProps,
    prepareRow,
    preFilteredRows,
    players,
    isAFLW
}) => {
    const sort = useSelector(statsLeadersSortSelector);
    const benchmarking = useSelector(statsLeadersBenchmarkingSelector);
    const dataType = useSelector(statsLeadersDataTypeSelector);

    const sortedPreFilteredRowsDesc = preFilteredRows.sort((a, b) =>
        a.values[sort.columnId] > b.values[sort.columnId] ? -1 : 1
    );

    const ranks = getRanks(sortedPreFilteredRowsDesc, sort.columnId);

    const getPlayer = _.memoize(
        (row) =>
            players.find(
                (_player) =>
                    _player.details.playerId === row.original.player.playerId
            ),
        (row) => row.id
    );

    return (
        <StatsTableBody
            rows={page}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            getBenchmark={(row, cell) =>
                getBenchmark(cell, benchmarking, getPlayer(row), dataType)
            }
            getCellId={(row, cell) => getId(cell, getPlayer(row))}
            getCellTitle={(row, cell) => getTitle(cell, getPlayer(row))}
            skipCells={['position', 'team']}
            primaryTooltipComponent={(row, cell, benchmark) => (
                <PlayerStatsTooltipContent
                    row={row.original}
                    cell={cell}
                    benchmark={benchmark}
                    players={players}
                    isAFLW={isAFLW}
                />
            )}
            customCells={{
                rank: (row, cell, cellProps) => (
                    <StatsLeadersRankCell {...cellProps} rank={ranks[row.id]} />
                ),
                player: (row, cell, cellProps) => (
                    <StatsLeadersPlayerCell
                        {...cellProps}
                        player={cell.value}
                        rank={ranks[row.id]}
                    />
                )
            }}
        />
    );
};

StatsLeadersTableBody.propTypes = {
    page: PropTypes.array.isRequired,
    getTableBodyProps: PropTypes.func.isRequired,
    prepareRow: PropTypes.func.isRequired,
    preFilteredRows: PropTypes.array.isRequired,
    players: PropTypes.array.isRequired,
    isAFLW: PropTypes.bool
};

const getBenchmark = (cell, benchmarking, player, dataType) => {
    const playerStats = player.stats[dataType.type];
    return benchmarking.isActive && playerStats
        ? playerStats[cell.column.id]?.benchmark
        : null;
};

const getId = (cell, player) =>
    `stats-leaders-${player.details.givenName}-${player.details.surname}-${
        STATS_COLUMNS[cell.column.id].id
    }`;

const getTitle = (cell, player) =>
    `${player.details.givenName} ${player.details.surname}: ${
        STATS_COLUMNS[cell.column.id].name
    }.`;
