import { getTranslation } from 'common/react/utils/translations';
import { STATS_COLUMNS_FOR_CATEGORY } from 'widgets/statspro/js/components/common/config/columns';
import { POSITIONS } from '../../../config/positions';

export const composeColumnData = (category, isAFLM) => [
    {
        Header: getTranslation('label.statsLeaders.rank'),
        accessor: 'rank',
        disableSortBy: true
    },
    {
        Header: getTranslation('label.statsLeaders.player'),
        accessor: 'player',
        disableSortBy: true
    },
    {
        Header: 'Position',
        accessor: 'position',
        filter: (rows, columnIds, values) => {
            const relevantPositions = POSITIONS.filter((pos) =>
                values.includes(pos.name)
            ).flatMap((pos) => pos.keys);

            return !values.length
                ? rows
                : rows.filter((row) => {
                      return relevantPositions.some(
                          (pos) => pos === row.values.player.position
                      );
                  });
        }
    },
    {
        Header: 'Team',
        accessor: 'team',
        filter: (rows, columnIds, values) => {
            return !values.length
                ? rows
                : rows.filter((row) => {
                      return values.some((team) => team === row.values.team);
                  });
        }
    },
    ...STATS_COLUMNS_FOR_CATEGORY[category]
        .filter((col) => {
            if (!isAFLM) {
                return col.id !== 'ratingPoints'; // remove ratingPoints for comps except AFLM
            }

            return col;
        })
        .map((col) => ({
            Header: col.abbreviation,
            accessor: col.id,
            sortDescFirst: true,
            sortType: 'basic' // this property ensures that negative values are considered lower than 0 in the sorting.
            // i.e an ascending sorting would be -1.2 -0.8 0 1 2
        }))
];
